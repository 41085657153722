@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.buttonsDiv {
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}

.hideDropdown {
  display: none;
}

.dropdown {
  display: inline;
}
