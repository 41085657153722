@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.tableContainer thead th {
  background-color: transparent;
  color: #c8c8c8;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.33px;
  border: none;
  text-transform: uppercase;
  border-spacing: 0;
  padding: 0 10px;

  :global {
    .ant-table-column-sorter .ant-table-column-sorter-inner-full {
      margin-top: -0.8em !important;
    }
  }
}

.tableContainer tbody tr {
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  &:last-child {
    margin-bottom: 40px;
  }
}

.tableContainer tr td {
  padding: 5px 10px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;

  &:first-child {
    width: 1%;
    border-left: 2px solid #fff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:last-child {
    padding: 5px 0;
    border-right: 2px solid #fff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
.tableContainer {
  :global {
    .ant-table-content .ant-table-scroll .ant-table-body {
      overflow-x: auto !important;
    }
    .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
      background: transparent;
    }
    .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
      background: transparent;
    }
    .ant-table-thead > tr > th.ant-table-column-sort {
      background-color: transparent;
      color: #000;
    }
    .ant-table-thead > tr > th .ant-table-column-sorter {
      display: none;
    }
    .ant-dropdown-trigger {
      padding: 0 5px 0 0;
    }
    .ant-tag {
      margin-right: 0;
    }
    .ant-table-column-has-actions {
      width: 50px;
    }
  }
}
.tableContainer .ant-table-tbody > tr > td {
  border-bottom: none;
}

.tableContainer .tableRow {
  background-color: #fcfcfc;
  height: 30px;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.39px;
  color: #000;
  &:hover {
    box-shadow: 0 0 5px 2px rgba(219, 227, 237, 0.8);
    background-color: rgba(24, 144, 255, 0.03);
  }
  &:focus {
    box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
    border: solid 1px @brandAccentBlue;
    background-color: #fcfcfc;
  }
}

.tableContainer table {
  border-spacing: 0 10px;
}
.tableHeader {
  .lastActiveText {
    color: #bfbfbf;
  }
}

.greyedOut {
  color: @fontGray;
}

.highlighted {
  color: @brandAccentBlue;
  background-color: @blueExtraLight;
  border: 1px solid @borderBlue;
}

@media screen and (max-width: @screen-md) {
  .tableContainer table {
    overflow-y: hidden;
  }
}

//column styling

.statusIndicator {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.statusText {
  color: #6a6a6a;
  letter-spacing: 0.4px;
  font-weight: 500;
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.capitalise {
  text-transform: capitalize;
}

.airportText,
.numberText {
  font-weight: 600;
}

.iconDivider {
  height: 100%;
  width: 2px;
  border-left: 1px solid #e6e8ed;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0;
}

.menuDropdownButton {
  border: none;
  background-color: transparent;
  padding: 0;
}

.icon {
  font-size: 20px;
}

.dateColumn {
  min-width: 120px;
}

.ataColumn {
  min-width: 80px;
}

.iconWrapper {
  text-align: center;
}

.ageText {
  color: #b7b7b7;
}

.poNumber {
  font-size: 13px;
  font-weight: 500;
  color: rgba(36, 45, 65, 0.9);
}

.dataBold {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.39px;
  color: #000;
}
.dataGrey {
  font-size: 12px;
  letter-spacing: 0.39px;
  color: #b7b7b7;
}

.dataLink {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.08px;
  color: #1990ff;
  span {
    display: flex;
    align-items: center;
  }
  img {
    margin-left: 4px;
  }
}

.pdfIcon {
  width: 12px;
  height: 16px;
}

.arrowIcon {
  width: 12px;
  height: 10px;
}
.emptyStateWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  .emptyState {
    height: 125px;
    margin-bottom: 10px;
  }
}

.actualCost {
  display: flex;
}

.percentage,
.greenPercentage {
  font-size: 12px;
  color: #d4696f;
  display: flex;
  align-items: center;
  img {
    margin: 0 5px;
  }
}

.greenPercentage {
  color: #34ba6d;
}

.arrowFlip {
  transform: rotateX(180deg);
}

.timeDiffShow {
  font-size: 12px;
  letter-spacing: 0.39px;
  color: #d4696f;
  margin-left: 4px;
}

.timeDiffHide {
  display: none;
}

.dropdownMenu {
  padding: 0;
  :global {
    .ant-dropdown-menu-item {
      padding: 0;
    }
    .ant-dropdown-menu-item:hover {
      background-color: transparent;
    }
  }
}
