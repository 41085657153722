@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.titleWrapper,
.contentWrapper {
  display: flex;
  align-items: center;
}

.contentWrapper {
  justify-content: space-between;
}

.statusIcon,
.statusIconRed {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border: 1px solid #b5b5b5;
  border-radius: 50%;
  padding: 2px;
}

.statusIconRed {
  border: 1px solid #e7293a;
}

.checkText {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #5a5a5a;
}

.checkStatusExpired,
.checkStatusValid,
.checkStatusNotCompleted,
.checkStatusCritical {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
}

.checkStatusExpired {
  color: #e7293a;
}
.checkStatusValid {
  color: #04c485;
}
.checkStatusNotCompleted {
  color: #7e8389;
}
.checkStatusCritical {
  color: #fad288;
}

.validFor {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  text-align: right;
  color: #5a5a5a;
}

.validForMobile {
  display: none;
}

.infoIcon {
  display: none;
}

.tooltipWrapper {
  display: flex;
  background-color: @white;
  padding: 15px 25px;
}

.tooltipLabel {
  font-size: 12px;
  color: #b9b9b9;
}

.tooltipDate {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.46px;
  color: #646464;
}

@media screen and (max-width: 800px) {
  .contentWrapper {
    .validFor {
      display: none;
    }
    .validForMobile {
      display: block;
      margin-left: 8px;
    }
    .infoIcon {
      display: block;
    }
  }
}
