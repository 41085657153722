@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.searchHolder,
.searchIconHolder,
.searchInputHolder {
  display: flex;
  align-items: center;
  height: 32px;
}

.searchHolder {
  button {
    background-color: transparent;
    border: none;
  }
}

.searchIconHolder {
  position: relative;
  z-index: 9;
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
}

.searchIcon {
  height: 1em;
  position: relative;
  cursor: pointer;
  margin-bottom: 2px;
  animation: searchIconActiveSlideBack 0.4s;
  &.searchIconActive {
    margin-right: -15px;
    animation: searchIconActiveSlide 0.4s;
    z-index: 10;
  }
  svg:hover {
    fill: @brandAccentBlue;
  }
}

.searchInput {
  height: auto;
  min-width: 17em;
  border: 1px solid @borderGray;
  border-radius: 16px;
  margin-left: -1em;
  padding: 0.3em 0 0.3em 2em;
  &::placeholder {
    color: rgba(36, 45, 65, 0.6);
  }
  &:focus {
    outline: none;
    border: 1px solid @brandAccentBlue;
    box-shadow: 0 0 2px rgba(24, 144, 255, 0.2);
  }
  &.searchInputActive {
    animation: searchInputVisibility 1s;
  }
}

.closeIconHolder {
  position: relative;
  z-index: 9;
  top: -2px;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  color: @black;
  transition: all 1s;
  cursor: pointer;
  &.closeIconHolderActive {
    opacity: 0.25;
    visibility: visible;
    :global {
      .anticon {
        vertical-align: 0;
      }
    }
    &:hover {
      color: @black;
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .searchInput {
    min-width: 16em;
    padding-right: 2em;
  }
}

@media screen and (max-width: @screen-sm) {
  .searchHolder {
    position: relative;
  }
  .searchIcon {
    position: absolute;
    top: 0.85em;
    right: 0.75em;
    opacity: 0.25;
    pointer-events: none;
  }
  .searchInput {
    opacity: 1;
    visibility: visible;
    padding-left: 1em;
    padding-right: 1.8em;
  }
  .closeIconHolder {
    visibility: hidden;
    &.closeIconHolderActive {
      visibility: hidden;
    }
  }
}

@keyframes searchIconActiveSlide {
  from {
    left: 212px;
  }
  to {
    left: 0;
  }
}

@keyframes searchIconActiveSlideBack {
  from {
    left: -212px;
  }
  to {
    left: 0;
  }
}

@keyframes searchInputVisibility {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
