@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.removeCol {
  padding-top: 3px;
}

.singleTask {
  margin-bottom: 15px;
  width: 100%;
  float: left;
}

.addNewCol {
  margin-top: -15px;
}

@media screen and (max-width: @screen-xl) {
  .singleTask {
    :global {
      .ant-col-md-8 {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .singleTask {
    :global {
      .ant-col-md-4 {
        width: 30%;
      }
      .ant-col-md-8 {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .singleTask {
    float: none;
    .removeCol {
      display: inline-block;
      width: 50%;
    }
    :global {
      .ant-col-md-4 {
        width: 50%;
        display: inline-block;
      }
      .ant-col-md-8 {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .singleTask {
    .taskDescription {
      border-bottom: 1px @borderGrey solid;
    }
    &:last-child {
      .taskDescription {
        border-bottom: 0;
      }
    }
    :global {
      .ant-col-md-4 {
        width: 50%;
      }
    }
  }
}
