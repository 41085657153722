.externalLayout {
  position: relative;
  :global {
    .antd-pro-components-global-header-index-right {
      .ant-spin {
        display: none;
      }
    }
  }
}
