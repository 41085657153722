@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.aircraftCard {
  width: 100%;
}

.widgetWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .settingsTitle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    span {
      font-size: 16px;
      color: @overviewSettingsButton;
    }
  }
  .showTotalsButtonWrapper,
  .showTotalsButtonWrapperPadded {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 4px;
    .aircraftTotalsButton {
      height: 26px;
      border-radius: 12.5px;
      border: solid 1px @buttonBorderColour;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.09px;
      text-align: right;
      color: @oveviewShowTotals;
      padding: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: transparent;
      .goArrow {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }
  .showTotalsButtonWrapperPadded {
    margin-bottom: 24px;
  }
}
