@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.mxItemRow {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.checkboxWrapper {
  padding-top: 5px;
  margin-right: 12px;
}

:global {
  .ant-tooltip-inner {
    text-align: center;
  }
}

@media screen and (max-width: @screen-sm) {
  .checkboxWrapper {
    padding-top: 0;
  }
}
