@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.widgetCardWrapper {
  border-radius: 5px;
  box-shadow: 0 0 10px 0 @overviewBoxShadow;
  border: solid 2px var(--white);
  background-color: @overviewBackgroundColour;
  width: 100%;
  height: 100%;
  padding: 50px 35px;
  display: flex;
  justify-content: space-between;
}
.progressCircleWrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.widgetCarousel {
  display: none;
}

@media screen and (max-width: @screen-xl) {
  .widgetCardWrapper {
    padding: 10px;
    display: block;
  }
}

@media screen and (max-width: @screen-md) {
  .widgetCardWrapper {
    padding: 10px;
    display: block;
  }
}

@media screen and (max-width: @screen-sm) {
  .widgetCardWrapper {
    display: none;
  }
  .widgetCarousel {
    display: inline-flex !important;
    width: 100%;
    :global {
      .ant-carousel {
        width: 100%;
      }
    }
  }
  .widgetCarouselItem {
    display: inline-flex !important;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    padding: 0 5px;
    :global {
      .ant-col {
        margin-bottom: 16px;
      }
    }
  }
}
