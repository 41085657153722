@import '../../utils/utils.less';
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 252px;

  .emptyText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    img {
      height: 115px;
    }
    span {
      color: @darkGrey;
      font-size: 14px;
      padding-top: 8px;
    }
  }
}
