@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.progressDivWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .divider {
    height: unset;
  }
}

.progressDiv {
  display: flex;
  span {
    font-size: 14px;
  }
}
.hoursWrapper {
  margin-bottom: 15px;
}

.hours {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.13px;
  color: #313745;
  margin-bottom: 15px;
}

.textDiv {
  padding-left: 11px;
  padding-right: 21px;
  div {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.12px;
    color: #313745;
  }
}
