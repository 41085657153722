@import '../../utils/utils.less';

.popoverBackground {
  background: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .popoverContainer {
    position: absolute;
    animation: fadeIn 0.3s 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
