@import '../../utils/utils.less';

.menuButton {
  padding: 0 1rem 0 0.5rem;
  color: @blue;
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;
  .moreIcon {
    height: 1rem;
    color: @blue;
  }
}

.menuWrapper {
  box-shadow: 0 0 10px 5px #dbe3ed;
  background-color: @white;
  border-radius: 4px;
  width: max-content;
  min-width: 80px;
  z-index: 210;
  overflow: visible;
  .menuItem {
    width: 100%;
    z-index: 20;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 80px;
    height: 37px;
    cursor: pointer;
    background-color: @white;
    border: none;
    span {
      padding: 10px;
    }
    a {
      color: rgba(0, 0, 0, 0.65);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: rgba(24, 144, 255, 0.05);
      color: #1890ff;
      a {
        color: #1890ff;
      }
    }

    &.delete {
      color: @red;
      background-color: rgba(202, 35, 34, 0.05);
    }
    &.delete:hover {
      background-color: rgba(202, 35, 34, 0.1);
    }
  }
}
