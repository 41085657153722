@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';
/* stylelint-disable */

.reference {
  font-weight: 500;
}

.dropdownIcon {
  font-size: 20px;
  padding-right: 5px;
  color: @brandAccentBlue;
  display: flex;
  align-items: center;
}

.defaultTable {
  td {
    word-break: break-all;
  }
}

.tableWrapper {
  max-height: calc(100vh - 290px);
  overflow-y: scroll;
}

:global {
  #workpackTable {
    -webkit-box-sizing: unset;
    box-sizing: unset;
  }
}

:global {
  #workpackTable .ant-layout,
  .ant-layout * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
}

.workpacksCard {
  background-color: #fafafa;
  border: 1px solid @white;
  border-radius: 3px;
  :global {
    .ant-card-body {
      padding: 0;
    }
  }
}

.menuButton {
  cursor: pointer;
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.emptyText {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  img {
    height: 115px;
  }
  span {
    color: @darkGrey;
    font-size: 14px;
    padding-top: 8px;
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.tableActions,
.filterButton,
.searchInput {
  display: flex;
  align-items: center;
}

.filterIcon,
.filterButton {
  margin-left: 0.5em;
  margin-right: 2em;
  height: 1em;
  width: 1em;
}

.filterButton {
  border: none;
  background-color: transparent;
  display: none;
}

.drawerHeader {
  margin: 12px 0 0;
  padding-left: 26px;
  padding-bottom: 1em;
  border-bottom: 1px solid @borderGrey;
  span {
    font-weight: 600;
    font-size: 16px;
  }
}

.searchInput {
  float: right;
  margin-bottom: 1em;
}

.threeDotMenu {
  height: 17px;
  cursor: pointer;
}

.pageContentWrapper {
  display: flex;
  .mainCol {
    flex: 1;
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
    .tableWrapper {
      overflow: scroll;
      width: calc(100vw - 730px);
    }
    .tableWrapperCollapsedMenu {
      overflow: scroll;
      width: calc(100vw - 590px);
    }
  }

  .filtersCol {
    width: 220px;
    flex-shrink: 0;
    margin-left: 30px;
  }
}

.dropdownMenu {
  padding: 0;
  :global {
    .ant-dropdown-menu-item {
      padding: 0;
    }
    .ant-dropdown-menu-item:hover {
      background-color: transparent;
    }
  }
}

.dropdownItem {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #fcfcfc;
  &:hover {
    background-color: rgba(24, 144, 255, 0.05);
    color: @brandAccentBlue;
  }
  &:focus {
    border: 1px solid @brandAccentBlue;
    border-radius: 4px 4px 0 0;
    color: @brandAccentBlue;
  }
}
.deleteItem {
  color: #ca2322;
  background-color: rgba(202, 35, 34, 0.05);
  &:hover {
    background-color: rgba(202, 35, 34, 0.1);
    color: #ca2322;
  }
  &:focus {
    background-color: rgba(202, 35, 34, 0.05);
    border: 1px solid #ca2322;
    border-radius: 0 0 4px 4px;
    color: #ca2322;
  }
}

.workpacksTable {
  :global {
    .ant-table-wrapper {
      table {
        border-collapse: separate;
        border-spacing: 0 1em;
      }
    }

    .ant-table-thead {
      tr > th {
        background-color: transparent;
        text-transform: uppercase;
        padding: 1em;
        color: #c8c8c8;
        font-size: 0.8em;
        border: none;
      }
    }

    .ant-table-tbody {
      tr {
        background-color: @white;
      }

      td {
        border: none;
        padding: 0.5em 1em;
      }

      td:first-child {
        border-top-left-radius: 0.35em;
        border-bottom-left-radius: 0.35em;
        padding-right: 0;
      }

      td:last-child {
        border-top-right-radius: 0.35em;
        border-bottom-right-radius: 0.35em;
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 1000px) {
  .tableWrapperCollapsedMenu {
    width: calc(100vw - 367px) !important;
  }
  .tableWrapper {
    width: calc(100vw - 507px) !important;
  }
  .pageContentWrapper {
    .filtersCol {
      display: none;
    }
  }
  .filterButton {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .addNewButton {
    width: auto;
    margin-bottom: 8px;
    float: right;
    z-index: 5;
    border-radius: 50%;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
  }
}

@media (max-width: 400px) {
  .tableWrapper {
    width: calc(100vw - 100px) !important;
  }
}
