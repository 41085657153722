@import '../../utils/utils.less';

.drawerBg {
  background-color: rgba(3, 44, 82, 0.33);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: 200;
}

.drawerWrapper {
  width: 0;
  height: calc(100vh - 24px);
  right: 0;
  top: 12px;
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
}

.drawerSlideOut {
  height: calc(100vh - 24px);
  width: 872px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideOut 0.3s;
}

.drawerSlideIn {
  height: calc(100vh - 24px);
  width: 872px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideIn 0.3s;
}

.drawerSlideOutFilters {
  height: calc(100vh - 24px);
  width: 240px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideOut 0.3s;
}

.drawerSlideInFilters {
  height: calc(100vh - 24px);
  width: 240px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideIn 0.3s;
}

.bgFadeOut {
  animation: fadeBg 0.3s;
}

.drawerCloseButton,
.drawerClosrButtonFilters {
  cursor: pointer;
  position: absolute;
  top: 33px;
  right: 35px;
  z-index: 201;
  border: none;
  background: transparent;
  .closeIcon {
    height: 12px;
    width: 12px;
  }
}

.drawerClosrButtonFilters {
  top: 12px;
  right: 12px;
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeBg {
  0% {
    background-color: rgba(50, 50, 50, 0.5);
  }
  100% {
    background-color: rgba(50, 50, 50, 0);
  }
}
