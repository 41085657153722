@import '~antd/lib/style/themes/default.less';
@import '../../../../../utils/utils.less';

.itemStatusTitle,
.itemDueDate {
  color: @titleBlack;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.09px;
}

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.acceptButton {
  color: @greenSubtle;
  border: 1px @greenSubtle solid;
  border-radius: 20px;
  font-weight: 500;
  margin: 0 5px;
  &:last-child {
    margin-right: 0;
  }
  &:active,
  &:focus {
    color: @greenSubtle;
    border: 1px @greenSubtle solid;
  }
  &:hover {
    background: @greenSubtle;
    color: @white;
    border: 1px @greenSubtle solid;
  }
}

.rejectButton {
  color: @redMedium;
  border: 1px @redMedium solid;
  border-radius: 20px;
  font-weight: 500;
  margin: 0 5px;
  &:last-child {
    margin-right: 0;
  }
  &:active,
  &:focus {
    color: @redMedium;
    border: 1px @redMedium solid;
  }
  &:hover {
    background: @redMedium;
    color: @white;
    border: 1px @redMedium solid;
  }
}

.skipButton {
  color: @lighterGray;
  border: 1px @lighterGray solid;
  border-radius: 20px;
  font-weight: 500;
  margin: 0 5px;
  &:last-child {
    margin-right: 0;
  }
  &:active,
  &:focus {
    color: @lighterGray;
    border: 1px @lighterGray solid;
  }
  &:hover {
    background: @lighterGray;
    color: @white;
    border: 1px @lighterGray solid;
  }
}

.changesWrapper {
  margin-right: 10px;
  font-weight: 500;
  color: @titleBlack;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: @white;
    background: @greenSubtle;
    display: inline-flex;
    margin-right: 8px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}

.itemCard {
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 15px;
  background: @whiteMedium50;
  :global {
    .ant-card-body {
      padding: 15px;
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 30px;
  .titleDiv {
    display: flex;
    align-items: center;
  }
}

.itemStatusTitleREMOVED {
  background: @redThin;
  color: @redOpaque;
  padding: 0 2px;
}

.itemStatusTitleUPDATE {
  background: @greenOpaque;
  color: @greenThin;
  padding: 0 2px;
}

.colWrapperKanban {
  border-bottom: 1px @borderGrey solid;
  padding-bottom: 15px;
  margin-bottom: 20px;
  &.colWrapperKanbanStrong {
    border-bottom: 1px @darkGrey solid;
  }
  .titleWrapper {
    margin-bottom: 0;
    align-items: flex-start;
    span,
    img {
      margin-top: 3px;
    }
  }
}

.rowTitle {
  color: @titleBlack;
  font-weight: 500;
  padding-left: 8px;
}

.showMD {
  display: none;
}

@media screen and (max-width: @screen-xl) {
  .mxTimings {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media screen and (max-width: @screen-md - 1px) {
  .showMD {
    display: block;
  }
  .mxDetails {
    :global {
      .ant-col-offset-1 {
        margin-left: 0;
      }
    }
  }
  .colWrapperKanban {
    margin-left: 8px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    &.colWrapperKanbanStrong {
      border-color: @borderGrey;
    }
    &.colWrapperKanbanLeft {
      opacity: 0.5;
    }
    &.colWrapperMXTimeDetails {
      padding-bottom: 0;
    }
  }
  .rowTitle {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: @screen-sm) {
  .rowTitle {
    font-size: 14px;
  }
  .footerWrapper {
    margin-bottom: 20px;
  }
}
