@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.filterBox {
  width: 240px;
  margin-bottom: 8px;
}

.card {
  border-radius: 2px;
  box-shadow: 0 0 10px 0 #dbe3ed;
  border: solid 2px #fff;
  background-color: #fafafa;
}

.tableList {
  .tableListOperator {
    margin-bottom: 16px;
    button {
      margin-right: 8px;
    }
  }
}

.icon {
  font-size: 20px;
}

.backButton {
  float: right;
  margin-right: 20px;
}

.tableListForm {
  :global {
    .ant-form-item {
      margin-bottom: 24px;
      margin-right: 0;
      display: flex;
      > .ant-form-item-label {
        width: auto;
        line-height: 32px;
        padding-right: 8px;
      }
      .ant-form-item-control {
        line-height: 32px;
      }
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .submitButtons {
    white-space: nowrap;
    margin-bottom: 24px;
  }
}

.addNewButton {
  width: auto;
  margin-bottom: 8px;
  float: right;
  z-index: 5;
}

@media screen and (max-width: @screen-lg) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 24px;
  }
}

@media screen and (max-width: @screen-md) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
  .addNewButton {
    width: auto;
    margin-bottom: 8px;
    float: right;
    border-radius: 50%;
    z-index: 5;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
  }
}

.highlightedRow {
  background: @aquaExtraLight;
}

@media screen and (max-width: @screen-sm) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
}
