@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.hiddenItem {
  display: none;
}

@media screen and (max-width: @screen-lg) {
  .formRow {
    :global {
      .ant-col-md-12 {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .formRow {
    float: none;
    :global {
      .ant-col-md-12 {
        width: 100%;
      }
    }
  }
}
