@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.resolutionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    font-weight: 600;
  }
}

.resolutionTitleResolved {
  margin-top: 30px;
  &:first-child {
    margin-top: 0;
  }
}

.attachmentsContainer {
  display: flex;
  flex-wrap: wrap;
}

.attachmentView {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  transition: all 0.2s;
  margin-bottom: 8px;
  &::before {
    padding-top: 100%;
    display: block;
    content: '';
  }
  &:hover {
    opacity: 0.85;
  }
}

.resolutionParts {
  margin-top: 16px;
}

.partsDescriptions {
  margin-bottom: 8px;
}

.textCapitalize {
  text-transform: capitalize;
}

.resolvedDescriptions {
  position: relative;
  :global {
    .ant-descriptions-item-content {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.resolutionSignatureImg {
  max-height: 80px;
  max-width: 70%;
}

.attachmentImage {
  max-height: 150px;
  border-radius: 2px;
  max-width: 100%;
}

.emptyDescItem {
  position: relative;
  :global {
    .ant-descriptions-item-colon {
      &::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .resolvedDescriptions {
    :global {
      .ant-descriptions-item {
        width: 100%;
      }
    }
  }
  .attachmentImage {
    max-height: none;
  }
}
