@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.rowDisabled {
  opacity: 0.5;
}

.progressValue {
  font-size: 18px;
  font-weight: 600;
  color: @gray;
  letter-spacing: 0.44px;
  margin-bottom: 3px;
}

.overdue {
  font-weight: 500;
  color: #ff4040;
}

.critical {
  font-weight: 500;
  color: #f2a650;
}

.progressValueSmall {
  font-size: 16px;
}

.progressUnits,
.progressTitle {
  font-size: 13px;
  color: @gray;
  text-transform: capitalize;
}

.progressTitle {
  margin-bottom: 4px;
}

.colCenter {
  text-align: center;
}

.warningCircle {
  width: 10px;
  height: 10px;
  background-image: linear-gradient(to bottom, #fad288, #f2a650);
  border-radius: 50%;
  margin-right: 5px;
}

.progressCircle {
  margin-bottom: 10px;
  :global {
    .ant-progress-circle-trail {
      stroke-width: 1px;
    }
  }
}

.widgetTitle {
  font-size: 14px;
  letter-spacing: 0.31px;
  color: @blackMedium;
  white-space: nowrap;
  font-weight: 500;
}

.includingTolerance {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: -0.09px;
  color: #9197a1;
  width: max-content;
}

.spacer {
  padding-bottom: 10px;
  margin: 0 auto;
  margin-bottom: 10px;
  border-bottom: 1px @borderGray solid;
  width: 35px;
}

.widgetTotals {
  color: @gray;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.05px;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 600;
  }
  .nextDueWrapper {
    display: flex;
    align-items: center;
    margin-left: 4px;
  }
}

.nextDueDate {
  display: block;
}

@media screen and (max-width: @screen-lg) {
  .progressCircle {
    :global {
      .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
  .progressValue {
    font-size: 16px;
  }

  .progressUnits {
    font-size: 12px;
    text-transform: capitalize;
  }
}

@media screen and (max-width: @screen-sm) {
  .progressCircle {
    .colCenter {
      padding: 0 32px;
    }
  }
  .widgetTotals {
    max-width: 92px;
  }
}
