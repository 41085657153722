.fixedHeaderExtra {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  transition: width 0.2s;
  padding: 0;
}

.staticHeader {
  width: 100%;
  padding: 0;
}
