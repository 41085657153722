@import '~antd/lib/style/themes/default.less';
@import '../../../utils/utils.less';

.modal {
  :global {
    .ant-modal-body {
      padding: 40px 60px;
      .gradient(linear; @whiteLight, @whiteMedium55; 90deg);
    }
    .ant-modal-close-x {
      img {
        transition: all 0.4s;
      }
      &:hover {
        img {
          transform: translateY(-2px);
        }
      }
    }
  }
}

.bigIcon {
  max-height: 150px;
  margin-left: -40px;
}

.modalTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: @titleDarkBlack;
  margin-bottom: 15px;
}

.smallIcon {
  height: 16px;
  margin-right: 6px;
}

.modalDescription {
  color: @titleDarkBlack;
  opacity: 0.7;
  margin-bottom: 20px;
}

.modalInput {
  margin-bottom: 10px;
  border-radius: 2px 0 0 2px;
}

.copyButton {
  padding: 9px 10px;
  border-radius: 0 2px 2px 0;
  height: 40.5px;
}

.modalParentRow {
  display: flex;
  align-items: center;
}

.inputWithButton {
  display: flex;
}

@media screen and (max-width: @screen-md) {
  .copyButton {
    height: 52.5px;
  }
  .bigIcon {
    max-height: 120px;
  }
  .modalParentRow {
    :global {
      .ant-col-md-10 {
        width: 35%;
      }
      .ant-col-md-14 {
        width: 65%;
      }
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .bigIcon {
    display: none;
  }
  .modal {
    :global {
      .ant-modal-body {
        padding: 30px 30px;
      }
    }
  }
  .modalParentRow {
    flex-wrap: wrap;
    :global {
      .ant-col-md-10 {
        width: 100%;
      }
      .ant-col-md-14 {
        width: 100%;
      }
    }
  }
}
