@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.listFilterRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateHolder {
  :global {
    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: 0;
    }
  }
}

.dropdownLink {
  font-weight: 500;
  height: 34px;
  padding: 7px 15px;
  border-radius: 20px;
  border: 1px transparent solid;
  transition: all 0.4s;
  color: @titleDarkBlack;
  .buttonText {
    color: @titleDarkBlack;
  }
  span {
    color: @mediumGray;
    font-weight: 400;
    display: inline-block;
    margin-left: 3px;
  }
  i {
    font-size: 8px;
    position: relative;
    top: -1px;
    color: @titleDarkBlack;
  }
  &:hover {
    cursor: pointer;
    border-color: @lightGrayMedium;
  }
}

.dateMenu {
  text-align: center;
}
@media screen and (max-width: @screen-xl) {
  .dateHolder {
    width: 300px;
    text-align: right;
  }
}
@media screen and (max-width: @screen-lg) {
  .listFilterRow {
    width: 70%;
    display: inline-flex;
  }
  .dateHolder {
    display: none;
  }
  .dropdownLink {
    margin-left: -17px;
  }
}

@media screen and (max-width: @screen-sm) {
  .listFilterRow {
    flex-wrap: wrap;
  }
  .dropdownLink {
    padding-left: 0;
    margin-top: 0;
    display: block;
    text-align: center;
    &:hover {
      border-color: transparent;
    }
  }
  :global {
    .ant-drawer-close {
      top: 0;
    }
  }
}
@media screen and (max-width: @screen-xs) {
  .listFilterRow {
    width: 60%;
    margin-right: 10%;
  }
}
