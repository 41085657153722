@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.gridWrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 82px 1fr 1fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.columnHeaderCycles,
.columnHeaderHours,
.columnHeaderDue {
  position: relative;
  border-radius: 5px;
  border: solid 1px #f9f9f9;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.34px;
  text-align: center;
  color: #bdbdbd;
}
.columnHeaderCycles {
  grid-column: 2;
}

.columnHeaderHours {
  grid-column: 3;
}

.columnHeaderDue {
  grid-column: 4;
}

.rowBackground {
  width: 100%;
  height: 100%;
  grid-column: 2 e('/') 5;
  background-color: @white;
  border-radius: 8px;
  box-shadow: 1px 2px 4px 0 rgba(224, 224, 224, 0.5);
  border: solid 1px @white;
}

.rowTitleWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.rowTitle {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.34px;
  color: #041430;
}

.cyclesColumn,
.hoursColumn,
.dueColumn {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.34px;
  text-align: center;
  color: #8f8f8f;
}
.cyclesColumn {
  grid-column: 2;
}
.hoursColumn {
  grid-column: 3;
}
.dueColumn {
  grid-column: 4;
}

.columnLine {
  width: 1px;
  height: 94%;
  border: solid 0.5px #aaa;
  grid-row-start: 1;
}

@media screen and (max-width: @screen-sm) {
  .gridWrapper {
    grid-template-columns: 70px 68px 68px 78px;
  }
}
