@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.formRowFlex {
  display: flex;
  flex-wrap: wrap;
}

.emptyDiv {
  height: 14px;
  width: 10px;
}

@media screen and (max-width: @screen-lg) {
  .partsRowParent,
  .partsRowParentBottom {
    :global {
      .ant-col-md-6 {
        width: 50%;
      }
    }
  }
}

.partsRowParentBottom {
  &::after {
    content: '';
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    border-bottom: 1px @borderGrey solid;
  }
}

@media screen and (max-width: @screen-sm) {
  .buttonCol {
    padding-top: 15px;
    position: relative;
    margin-bottom: 25px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -50%;
      width: 100%;
      border-bottom: 1px @borderGrey solid;
    }
    &:last-child,
    &:nth-last-child(2) {
      &::after {
        display: none;
      }
    }
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}
