@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.contentRow,
.contentRowNoPad {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #5a5a5a;
  align-items: center;
  justify-content: space-between;
}

.checkStatusExpired,
.checkStatusValid,
.checkStatusNotCompleted,
.checkStatusCritical {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
}

.checkStatusExpired {
  color: #e7293a;
}
.checkStatusValid {
  color: #04c485;
}
.checkStatusNotCompleted {
  color: #7e8389;
}
.checkStatusNotCritical {
  color: #fad288;
}

.statusIcon,
.statusIconRed {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border: 1px solid #b5b5b5;
  border-radius: 50%;
  padding: 2px;
}

.statusIconRed {
  border: 1px solid #e7293a;
}

.validFor {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  text-align: right;
  color: #5a5a5a;
}

.tooltipWrapper {
  display: flex;
  background-color: @white;
  padding: 15px 25px;
}

.tooltipLabel {
  font-size: 12px;
  color: #b9b9b9;
}

.tooltipDate {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.46px;
  color: #646464;
}

.lastPerformedWrapper {
  margin-right: 25px;
}

:global {
  .ant-tooltip {
    max-width: none;
  }
  .ant-tooltip-inner {
    padding: 0;
  }
  .ant-tooltip-arrow::before {
    background-color: @white;
  }
}
