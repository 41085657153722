@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.paginationParent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px @borderGrey solid;
  color: @textBlack;
}

.paginationInput {
  height: 24px;
  width: 40px;
  border-radius: 12px;
  text-align: center;
  margin-left: 4px;
  :global {
    .ant-input-number-handler-wrap {
      display: none;
    }
    input {
      padding: 0 3px;
      height: 100%;
      text-align: center;
      font-size: 13px;
      color: @titleDarkBlack;
      letter-spacing: -0.08px;
    }
  }
}

.pagePaginator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
  span {
    display: inline-block;
    margin-left: 4px;
  }
}

.pageSizer {
  width: ~'calc(100% - 150px)';
  display: flex;
  justify-content: center;
  align-items: center;
}

.prevArrow,
.nextArrow {
  height: 24px;
  width: 24px;
  border: 1px @borderGrey solid;
  padding: 0;
  color: @titleDarkBlack;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.4s;
  &:hover,
  &:active,
  &:focus {
    border: 1px @borderGrey solid;
    color: @titleDarkBlack;
  }
}
.prevArrow {
  &:hover {
    transform: translateX(-2px);
  }
}
.nextArrow {
  margin-left: 6px;
  &:hover {
    transform: translateX(2px);
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

@media screen and (max-width: @screen-sm) {
  .pageSizer {
    justify-content: flex-start;
  }
}
