@import '~antd/lib/style/themes/default.less';

.notValidBrowser {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0;
    &.contact {
      margin-top: 45px;
    }
    a {
      font-weight: 600;
    }
  }
  ul {
    padding: 0;
    margin-top: 15px;
    li {
      margin-bottom: 3px;
      font-weight: 600;
    }
  }
  .browserList {
    width: 30%;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
  }
}

.logo {
  height: 50px;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  margin-bottom: 20px;
  img {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    margin-right: 8px;
  }
}
