@import '../../../utils/utils.less';

.hoursInput,
.minsInput {
  width: 55px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  padding: 11px;
}

.minsInput {
  width: 43px;
}
