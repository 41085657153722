@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.signoffMethodRow {
  margin-top: 16px;
  padding-bottom: 18px;
}

.formRow {
  .formItem {
    :global {
      .ant-form-item-label {
        line-height: 1.5;
      }
    }
  }

  @media screen and (max-width: @screen-xl) {
    .formRow {
      :global {
        .ant-col-md-8 {
          width: 50%;
        }
        .ant-col-md-4 {
          width: 25%;
        }
      }
    }
  }

  @media screen and (max-width: @screen-lg) {
    .formRow {
      :global {
        .ant-col-md-4 {
          width: 30%;
        }
        .ant-col-md-8 {
          width: 60%;
        }
      }
    }
  }

  @media screen and (max-width: @screen-md) {
    .formRow {
      float: none;
      :global {
        .ant-col-md-4 {
          width: 50%;
          display: inline-block;
        }
        .ant-col-md-8 {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: @screen-sm) {
    .formRow {
      :global {
        .ant-col-md-4 {
          width: 100%;
        }
      }
    }
  }
}
