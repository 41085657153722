@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.pageDetails {
  padding: 32px 0;
  margin-bottom: 32px;
  background: @thinGray;
  border-radius: 4px 4px 0 0;
  color: @titleDarkBlack;
  .pageDetailsTitle {
    font-size: 16px;
  }
  .pageDetailsSecondary {
    font-size: 14px;
  }
  :global {
    .ant-row {
      display: flex;
      align-items: center;
    }
    span {
      font-weight: 600;
    }
  }
}
.SRPlink {
  font-size: 16px;
}

.srpWrapper {
  display: flex;
  justify-content: space-evenly;
  min-width: 300px;
}

.colLeft {
  text-align: left;
}

.colRight {
  text-align: right;
}

.pagePadding {
  padding: 0 32px;
}

.singleRowSection {
  margin-bottom: 32px;
  &:last-of-type {
    margin-top: -32px;
    margin-bottom: 48px !important; // override antd styles
  }
}

.selectAllBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  border-radius: 2px;
  border: 1px solid #81bfff;
  margin: 25px 32px;
  .infoWrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #181818;
    border-radius: 2px;
    .infoIconWrapper {
      margin-right: 20px;
      width: 40px;
      height: 52px;
      border-radius: 2px;
      background-image: linear-gradient(197deg, #81bfff, #57aaff);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 20px;
        width: 14px;
      }
    }
  }
  .selectAllWrapper {
    margin-right: 20px;
    .selectAllButton {
      border-radius: 12.5px;
      border: solid 1px #e6e6e6;
      padding: 0 10px;
      font-size: 14px;
      font-weight: 500;
      text-align: right;
      color: #108fff;
      cursor: pointer;
    }
  }
}

.sectionTitle {
  padding-bottom: 6px;
  margin-bottom: 24px;
  border-bottom: 1px @borderGrey solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sectionTitleInnerWrapper {
    .overTitle {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.19px;
      margin-bottom: 0;
      color: @gray;
    }
    .titleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.25px;
        color: @titleDarkBlack;
      }
    }
    .titleToggle {
      color: @gray;
      .hide {
        display: block;
      }
      .show {
        display: none;
      }
      &.titleToggleActive {
        .hide {
          display: none;
        }
        .show {
          display: block;
        }
      }
    }
    .toggleButton {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .pageDetails {
    .colLeft {
      width: 50%;
    }
    .colRight {
      width: 50%;
    }
  }
  .singleRowSection {
    :global {
      .ant-btn-primary {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .sectionTitle {
    margin-bottom: 16px;
  }
  .pageDetails {
    padding: 16px 0;
    .colLeft {
      width: 100%;
      text-align: center;
      margin-bottom: 6px;
    }
    .colRight {
      width: 100%;
      text-align: center;
    }
    :global {
      .ant-row {
        flex-wrap: wrap;
      }
    }
  }
  .pagePadding {
    padding: 0 16px;
  }
}
