@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.aircraftImg {
  width: 100%;
  height: 100px;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 24px;
}

.aircraftText {
  color: @titleDarkBlack;
  display: flex;
  align-items: center;
  line-height: 1.1;
  .aircraftReg {
    font-size: 16px;
    font-weight: 500;
    margin-right: 12px;
    white-space: nowrap;
  }
  .aircraftType {
    font-size: 14px;
    white-space: pre;
  }
}

.infoWrapper {
  display: flex;
  margin-top: 17px;
  align-items: center;
}

.infoLabel {
  font-size: 14px;
  color: #b9b9b9;
  margin-right: 40px;
}

.lastFlown {
  text-transform: capitalize;
}

@media screen and (max-width: @screen-lg) {
  .aircraftText {
    .aircraftType {
      white-space: pre-wrap;
      line-height: 1.2;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .aircraftImg {
    width: 50%;
  }
  .aircraftText {
    width: 50%;
    padding-left: 24px;
    flex-wrap: wrap;
    .aircraftReg {
      width: 100%;
      margin-bottom: 5px;
    }
    .aircraftType {
      width: 100%;
    }
  }
  .parentAircraftDetails {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: @screen-sm) {
  .aircraftText {
    width: 100%;
    padding-left: 0;
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .aircraftImg {
    width: 100%;
    margin-top: 8px;
  }
  .parentAircraftDetails {
    flex-wrap: wrap;
  }
}
