@import '~antd/lib/style/themes/default.less';
@import '../../../../../utils/utils.less';

// important is required to override inline styles of calendar component
.calendarItem {
  background: @redThin !important;
  color: @redOpaque !important;
  border-radius: 6px !important;
  height: 18px !important;
  min-width: 27px !important;
  border: 0 !important;
  margin-left: 0 !important;
  line-height: 1 !important;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  top: 15px !important;
  &.calendarItemNew {
    background: @greenOpaque !important;
    color: @greenThin !important;
  }
  &.calendarItemOverlapOld {
    top: 6px !important;
  }
  &.calendarItemOverlapNew {
    top: 28px !important;
  }
  :global {
    .rct-item-content {
      text-overflow: ellipsis;
      width: 100%;
      padding: 0 3px !important;
    }
  }
}

.calendarWrapper {
  margin-bottom: 30px;
  pointer-events: none;
  .evenYearCol {
    background: @lightTransparentGray;
    opacity: 0.5;
  }
  :global {
    .react-calendar-timeline .rct-header-root {
      background: transparent;
      border-bottom: 1px @borderGrey solid;
      color: @titleBlack;
      font-weight: 500;
      & > div:first-child {
        display: flex;
        align-items: flex-end;
        padding-bottom: 9px;
      }
      .rct-calendar-header {
        border: 0;
      }
    }
    .rct-sidebar {
      border: 0;
      .rct-sidebar-row {
        border-bottom: 1px @borderGrey solid;
        font-size: 12px;
        padding-left: 0;
      }
    }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
      border-bottom: 1px @borderGrey solid;
    }
    .rct-vertical-lines {
      .rct-vl {
        border-left: 1px @borderGrey dashed;
        &.rct-day-0,
        &.rct-day-6 {
          background: @lightTransparentGray;
          opacity: 0.5;
        }
        &.rct-vl-first {
          border-left-width: 1px;
        }
      }
    }
  }
  .headerMonth {
    color: @lighterGray;
    font-weight: 600;
    white-space: nowrap;
    & > div {
      background: transparent;
      font-size: 12px;
      border: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: flex-start;
      height: 100%;
      display: flex;
      align-items: center;
    }
    &.headerMonthYear {
      & > div {
        &.monthIntervalEvenYear {
          background: @lightTransparentGray;
          opacity: 0.5;
          padding-left: 4px;
          color: @titleBlack;
        }
      }
    }
  }
  .headerDay {
    height: 50px !important;
    .dayInterval {
      font-size: 12px;
      background: transparent;
      border-bottom: 0;
      border-left: 1px @borderGrey dashed;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.dayIntervalWeekend {
        background: @lightTransparentGray;
        opacity: 0.5;
      }
      span {
        position: relative;
        top: -4px;
        color: @lighterGray;
        font-size: 8px;
        left: 1px;
      }
    }
    &.headerDayYear {
      .dayIntervalEvenYear {
        background: @lightTransparentGray;
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .calendarWrapper {
    display: none;
  }
}
