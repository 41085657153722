@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.headerWrapper {
  width: 100%;
  padding-top: 20px;
  padding-left: 46px;
  display: flex;
  justify-content: space-between;
}

.drawerComponent {
  height: calc(100vh - 32px);
  :global {
    .ant-drawer-content {
      border-radius: 5px;
    }
  }
}

.inputWrapper {
  display: flex;
  padding-left: 46px;
  margin-top: 25px;
  margin-bottom: 34px;
}

.label,
.labelActive {
  font-size: 12px;
  color: #b9b9b9;
}

.tripNumberInput {
  width: 82px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.46px;
  color: #646464;
  padding-left: 10px;
}

.dateWrapper {
  margin: 0 50px;
}

.dateWrapperMobile {
  margin: 15px 0;
}

.tripDateInput {
  height: 40px;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.46px;
  color: #646464;
  padding-left: 10px;
}

.srpWrapper {
  display: flex;
  .label {
    opacity: 0.3;
  }
  .labelActive {
    opacity: 1;
  }
}

.srpNumberInput,
.srpNumberInputActive {
  width: 82px;
  height: 40px;
  opacity: 0.3;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  padding-left: 10px;
}

.srpNumberInputActive {
  opacity: 1;
}

.unlockButtonWrapper {
  display: flex;
  align-items: flex-end;
  padding-bottom: 9px;
  padding-left: 10px;
  .unlockButton {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05px;
    color: #1990ff;
    cursor: pointer;
  }
}

.unlockHidden {
  display: none;
}

.headerWrapperInner {
  display: flex;
}

.srp {
  font-size: 14px;
  letter-spacing: -0.09px;
  color: #1990ff;
  a {
    font-weight: 400;
  }
}

.closeIcon {
  padding-right: 30px;
  img {
    cursor: pointer;
  }
}

.headerDate {
  margin: 0 50px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.06px;
  color: #646464;
}

.subHeading {
  padding-left: 46px;
  padding-right: 96px;
  font-size: 14px;
  letter-spacing: 0.66px;
  color: #646464;
  padding-top: 25px;
  display: flex;
  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}

.buttonsHidden {
  padding: 17px;
  button {
    display: none;
  }
}

.buttons {
  padding-top: 25px;
  padding-left: 46px;
  padding-bottom: 25px;
  button {
    border-radius: 20px;
    border: solid 1px #1990ff;
    margin-right: 10px;
    color: #1990ff;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
    span {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.05px;
      color: #1990ff;
      margin-left: 5px;
    }
  }
}

.editIcon,
.overrideIcon {
  width: 16px;
  height: 16px;
}
.overrideIcon {
  transform: rotateY(180deg);
}

.tripUpdateHeader,
.tripUpdateHeaderMobile {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.07px;
  color: #181818;
}

.tripUpdateHeaderMobile {
  margin-bottom: 15px;
}

@media screen and (max-width: @screen-sm) {
  .headerWrapper {
    padding: 0;
  }
  .subHeading {
    padding-left: 0;
    padding-right: 0;
  }
  .buttons {
    padding-left: 0;
  }
  .headerDate {
    margin: 0 8px;
  }
}

@media screen and (max-width: 700px) and (max-height: 400px) {
  .drawerComponent {
    :global {
      .ant-drawer-content {
        width: 100vw;
      }
    }
  }
}
