@import '../../utils/utils.less';
/* stylelint-disable length-zero-no-unit */

.thumbNailButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  .attachmentThumbnail {
    width: 70px;
    padding: 5px;
    object-fit: cover;
    height: 70px;
  }
}

.downloadAndDeleteRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 10px;
  button {
    height: 25px;
    width: 25px;
    border-radius: 5px;
    margin: 5px;
    border: none;
    background-color: transparent;
  }
  .deleteButton {
    background: @red;
    padding: 3px;
    cursor: pointer;
    img {
      vertical-align: baseline;
    }
  }
  .downloadButton {
    background: @blue;
    padding: 3px;
    vertical-align: bottom;
    cursor: pointer;
    img {
      vertical-align: baseline;
    }
  }
}

.attachmentWrapper {
  display: flex;
  flex-wrap: wrap;
}

.fileViewContentWrapper {
  color: white;
  display: flex;
  height: 100%;
  .fileViewCol {
    position: relative;
    display: flex;
    flex: 1 1 30%;
    height: 100%;
    align-items: center;
    flex-direction: column;
  }

  .fileViewControlButtons {
    position: absolute;
    top: 50vh;
    border: 1px solid white;
    border-radius: 50%;
    width: 30px;
    line-height: 10px;
    height: 30px;
    cursor: pointer;
  }

  .imageWrapper {
    height: 100%;
    width: 800px;
    display: flex;
    align-items: center;
  }

  .pdfWrapper {
    height: 100%;
    width: 800px;
    display: flex;
    align-items: center;
  }

  .carouselImage {
    width: 100%;
  }

  .closeFileViewButton {
    width: 15px;
    padding-top: 10px;
    cursor: pointer;
  }

  .imgTitle {
    padding-top: 10px;
  }
}

.uploadButton {
  cursor: pointer;
  padding: 10px;
  img {
    width: 50px;
  }
}

.fileInput {
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
  img {
    width: 50px;
  }
}

.fileViewerBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: @brandDarkBlue;
  z-index: 1005;
  opacity: 0;
  transition: all 0.5s;
  button {
    border: none;
    background-color: transparent;
  }
}

.hideMe {
  transition: all 0.5s;
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

@media (max-width: 1200px) {
  .fileViewContentWrapper {
    .pdfWrapper {
      width: 400px;
    }
  }
}

@media (max-width: 600px) {
  .fileViewContentWrapper {
    .pdfWrapper {
      width: 300px;
      height: 600px;
    }
  }
}
