@import '~antd/lib/style/themes/default.less';
@import '../../../../utils/utils.less';

.filtersDrawer {
  :global {
    .ant-drawer-body {
      padding-left: 32px;
    }
  }
}
.filtersIcon {
  height: 12px;
  margin-right: 5px;
}
@media screen and (max-width: @screen-lg) {
  .filtersDrawer {
    :global {
      .ant-drawer-body {
        padding-top: 20px;
        padding-right: 20px;
      }
    }
  }
}
