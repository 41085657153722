@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.itemTitle {
  font-weight: 500;
  color: @titleBlack;
  margin-bottom: 8px;
}

.itemDetails {
  font-size: 12px;
  color: @gray;
  text-transform: uppercase;
  .itemDetailsUpperWrapper {
    height: 60px;
  }
  span {
    display: block;
    text-transform: none;
    font-size: 14px;
    color: @mxBlack;
    font-weight: 500;
    &.inlineSpan {
      display: flex;
      align-items: center;
    }
    &.spanREMOVED {
      background: @redThin;
      color: @redOpaque;
    }
    &.spanUPDATE {
      background: @greenOpaque;
      color: @greenThin;
    }
    &.columnTitle {
      font-size: 12px;
      text-transform: uppercase;
      color: @lighterGray;
      letter-spacing: 0.15px;
    }
  }
  div.secondarySpan,
  span.secondarySpan {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.45px;
    color: #646464;
    text-transform: lowercase;
    margin-left: 3px;
  }
}
.columnSpan {
  font-weight: 500;
}

.warningCircle {
  width: 10px;
  height: 10px;
  background-image: linear-gradient(to bottom, #fad288, #f2a650);
  border-radius: 50%;
  margin-right: 5px;
}

.visibleTolerance {
  font-size: 12px;
  font-weight: 300;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: 0.45px;
  color: #646464;
}

.itemDetailsInline {
  p {
    display: flex;
    align-items: flex-start;
    .columnTitle {
      margin-top: 2px;
      width: 120px;
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .itemDetailsInline {
    p {
      .columnTitle {
        width: 100px;
      }
    }
  }
}

@media screen and (max-width: @screen-md) and (max-height: 500px) {
  .itemDetails {
    display: flex;
    .itemDetailsUpperWrapper {
      padding-right: 80px;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .itemDetails {
    display: flex;
    .itemDetailsUpperWrapper {
      padding-right: 80px;
    }
  }
}
