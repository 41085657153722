@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.summaryItemWrapperDisabled {
  opacity: 0.5;
  .statusTitle {
    span {
      color: @blackMedium;
    }
  }
}

.summaryItemWrapper {
  display: flex;
  text-align: left;
  justify-content: space-between;
  :global {
    .ant-badge-status {
      width: 100%;
    }
    .ant-progress {
      width: 40%;
    }
  }
}

.statusTitle {
  font-size: 14px;
  line-height: 1.86;
  color: rgba(49, 55, 69, 0.6);
  display: flex;
  align-items: center;
  span {
    font-weight: 500;
    margin-right: 4px;
  }
  &.statusTitleInline {
    width: 120px;
  }
  .numberWrapper {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.warningCircle {
  width: 10px;
  height: 10px;
  background-image: linear-gradient(to bottom, #fad288, #f2a650);
  border-radius: 50%;
  margin-right: 5px;
}

.statusTitleGreen {
  span {
    color: @green;
  }
}

.statusTitleAmber {
  span {
    color: @amber;
  }
}

.statusTitleRed {
  span {
    color: @red;
  }
}

@media screen and (max-width: @screen-lg) {
  .summaryItemWrapper {
    :global {
      .ant-progress {
        width: 40%;
      }
    }
  }
  .statusTitle {
    width: 60%;
  }
}

@media screen and (max-width: @screen-md) {
  .summaryItemWrapper {
    :global {
      .ant-progress {
        width: ~'calc(100% - 150px)';
      }
    }
  }
  .statusTitle {
    width: 150px;
  }
}
