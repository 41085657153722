@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.tableTitle {
  font-weight: 500;
  font-size: 16px;
  color: @blackMedium;
}

.defaultTable {
  td {
    word-break: break-word;
  }
}

.progressBar {
  width: 100px;
}

.rowTitle {
  margin-top: -12px;
  margin-bottom: -20px;
  margin-left: 4px;
}

.statusTitle {
  width: 130px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    font-weight: 500;
  }
}

.emptyText {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  img {
    height: 115px;
  }
  span {
    color: @darkGrey;
    font-size: 14px;
    padding-top: 8px;
  }
}

.columnSpan {
  font-weight: 500;
}

.cardButtons {
  float: right;
}

.backIcon {
  color: @black;
  font-size: 20px;
  margin-top: 5px;
}

.filterBox {
  width: 240px;
  margin-bottom: 8px;
}

.statusTitleGreen {
  span {
    color: @green;
  }
}

.statusTitleAmber {
  span {
    color: @amber;
  }
}

.statusTitleRed {
  span {
    color: @red;
  }
}

.statusTag {
  margin-bottom: 5px;
}

.tooltipIcon {
  margin-right: 10px;
}

.textSecondary {
  color: @blackMedium;
}

.progress {
  width: 100px;
  margin-right: 10px;
}
.ataNumber {
  font-weight: 500;
}
.centeredText {
  text-align: center;
}
.workpackButton {
  float: right;
}

.hiddenTable {
  text-align: center;
  font-weight: 500;
  background-color: @offWhite;
  padding: 15px;
  margin: 15px 0;
  position: relative;
}

.maintenanceDetails {
  font-size: 12px;
  color: @lightGray;
  text-transform: uppercase;
  span {
    display: block;
    text-transform: none;
    font-size: 14px;
    color: @blackMedium;
  }
  span.secondarySpan {
    font-weight: 300;
    display: block;
    font-size: 12px;
    text-transform: lowercase;
  }
}

.maintenanceDetailsTitle {
  font-size: 12px;
  color: @lightGray;
  text-transform: uppercase;
  margin-bottom: 8px;
  line-height: 1.25;
  span {
    display: inline;
    text-transform: none;
    font-size: 14px;
    color: @blackMedium;
    font-weight: 500;
  }
}

.scheduledDetails {
  font-size: 12px;
  color: #ccc;
  text-transform: uppercase;
  span {
    display: block;
    text-transform: none;
    font-size: 14px;
    color: @blackMedium;
  }
  span.secondarySpan {
    font-weight: 300;
    display: block;
    font-size: 12px;
    text-transform: lowercase;
  }
}

.llpDetailsTitle {
  font-size: 12px;
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: 8px;
  line-height: 1.25;
  span {
    display: inline;
    text-transform: none;
    font-size: 14px;
    color: @blackMedium;
    font-weight: 500;
  }
}

.llpDetails {
  font-size: 12px;
  color: #ccc;
  text-transform: uppercase;
  span {
    display: block;
    text-transform: none;
    font-size: 14px;
    color: @blackMedium;
  }
  span .secondarySpan {
    font-weight: 300;
    display: block;
    font-size: 12px;
    text-transform: lowercase;
  }
}

.oopDetails {
  font-size: 12px;
  color: #ccc;
  text-transform: uppercase;
  span {
    display: block;
    text-transform: none;
    font-size: 14px;
    color: @blackMedium;
  }
  span .secondarySpan {
    font-weight: 300;
    display: block;
    font-size: 12px;
    text-transform: lowercase;
  }
}
.oopDetailsTitle {
  font-size: 12px;
  color: #ccc;
  text-transform: uppercase;
  margin-bottom: 8px;
  line-height: 1.25;
  span {
    display: inline;
    text-transform: none;
    font-size: 14px;
    color: @blackMedium;
  }
  .seeMoreDescription {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    span {
      font-size: 12px;
    }
  }
}

.taskRefTag {
  display: inline-block;
  white-space: normal;
}

.itemRefTitle {
  margin-bottom: 3px;
}

.divFontWeight {
  font-weight: 500;
}
