.buttonWrapper {
  button {
    padding: 0;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
}
