@import '../../utils/utils.less';

.paginationWrapper {
  .pageNumber {
    color: #242d41;
    font-size: 13px;
    margin: 0 15px;
  }
  .chevronWrapper {
    width: 24px;
    height: 24px;
    border: 1px solid rgba(36, 45, 65, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .chevron,
    .chevronLeft {
      width: 6px;
      margin-left: 2px;
    }
    .chevronLeft {
      transform: rotate(180deg);
      margin-left: -2px;
    }
  }

  .normal {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .opacity {
    opacity: 0.3;
    border: none;
    background-color: transparent;
  }
}
