@import '~antd/lib/style/themes/default.less';
@import '../../../../utils/utils.less';

.modal {
  width: 100% !important; // need to override inline added styles
  top: 0;
  padding-bottom: 0;
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-close {
      top: 5px;
    }
    .ant-btn-link[disabled] {
      border-color: rgba(0, 0, 0, 0.25);
    }
  }
}

.layoutWrapper {
  min-height: 100vh;
}

.headerWrapper {
  background: @white;
  height: auto;
  border-bottom: 1px @borderGrey solid;
}

.siderWrapper {
  background: @whiteMedium40;
  padding: 20px 32px;
}

.contentWrapper {
  background: @white;
  padding: 0 50px;
  padding-bottom: 30px;
}

.modalHeaderWrapper {
  height: 70px;
  display: flex;
  align-items: center;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLeft,
.headerRight {
  display: flex;
  align-items: center;
}

.aircraftRegistration {
  color: @titleBlack;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}

.aircraftDescription {
  color: @titleBlack;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  margin-left: 35px;
}

.arrowTextHolder {
  color: @titleDarkBlack;
  margin: 0 15px;
  span {
    font-weight: 600;
  }
}

.leftArrow,
.rightArrow {
  color: @titleDarkBlack;
  font-size: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px @borderGrey solid;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  &:active,
  &:focus,
  &:hover {
    color: @titleDarkBlack;
    border: 1px @borderGrey solid;
  }
}
.leftArrow {
  &:hover {
    transform: translateX(-2px);
  }
}
.rightArrow {
  &:hover {
    transform: translateX(2px);
  }
}

.itemSwitcher {
  display: flex;
  align-items: center;
}

.buttonBack {
  font-weight: 600;
}

.rowContent {
  padding: 0 90px;
}

.draftQueueWrapper {
  margin: 50px 20px;
}

.draftQueue {
  padding: 25px 60px;
  .gradient(linear; @white, @whiteThin; 100deg);

  border-radius: 10px;
  box-shadow: 0 2px 40px 0 rgba(200, 200, 200, 0.32);
}

.summaryHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .subTitle {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: @lighterGray;
  }
  .title {
    font-size: 24px;
    color: @titleBlack;
    letter-spacing: -0.16px;
    font-weight: 500;
  }
  .summaryLinkWrapper {
    text-align: right;
  }
  .summaryLink {
    font-weight: 600;
  }
}

.summaryContent {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  .progressWrapper {
    width: 20%;
    display: flex;
    border-right: 1px @borderGrey solid;
    padding-left: 30px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
    &.progressWrapperDisabled {
      opacity: 0.5;
    }
  }
  .progressContent {
    display: flex;
  }
  .progressDescription {
    margin-left: 5px;
    .title {
      letter-spacing: -0.09px;
      font-size: 14px;
      color: @lighterGray;
      margin-bottom: 2px;
      font-weight: 500;
    }
    .count {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.12px;
      color: @titleBlack;
    }
  }
}

.listTitle,
.listTitleMobile {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px @borderGrey solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    color: @titleBlack;
    font-weight: 500;
    font-size: 16px;
  }
}

.listTitleMobile {
  display: none;
}

.filtersDrawerWrapper {
  display: none;
}

.buttonWrapper {
  display: flex;
}

.acceptButton {
  color: @greenSubtle;
  border: 1px @greenSubtle solid;
  border-radius: 20px;
  font-weight: 500;
  margin: 0 5px;
  &:last-child {
    margin-right: 0;
  }
  &:active,
  &:focus {
    color: @greenSubtle;
    border: 1px @greenSubtle solid;
  }
  &:hover {
    background: @greenSubtle;
    color: @white;
    border: 1px @greenSubtle solid;
  }
}

.rejectButton {
  color: @redMedium;
  border: 1px @redMedium solid;
  border-radius: 20px;
  font-weight: 500;
  margin: 0 5px;
  &:last-child {
    margin-right: 0;
  }
  &:active,
  &:focus {
    color: @redMedium;
    border: 1px @redMedium solid;
  }
  &:hover {
    background: @redMedium;
    color: @white;
    border: 1px @redMedium solid;
  }
}

.pageMessageWrapper {
  margin-top: 20px;
  display: none;
}

.individualWrapper {
  margin-top: 30px;
}

@media screen and (max-width: @screen-xxl) {
  .rowContent {
    padding: 0 70px;
  }
  .contentWrapper {
    padding: 0 30px;
    padding-bottom: 30px;
  }
  .summaryContent {
    .progressWrapper {
      padding-left: 15px;
      padding-right: 15px;
      width: auto;
    }
    .progressContent {
      :global {
        .ant-progress-inner {
          width: 45px !important; //override inline styles
        }
      }
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .siderWrapper {
    display: none;
  }
  .filtersDrawerWrapper {
    display: block;
    text-align: right;
  }
  .periodsWrapper {
    display: none;
  }
  .summaryHeader {
    .title {
      font-size: 22px;
    }
  }
  .summaryContent {
    .progressContent {
      :global {
        .ant-progress-inner {
          width: 40px !important; // override inline styles
        }
      }
    }
    .progressDescription {
      .title {
        font-size: 12px;
        margin-bottom: 0;
      }
      .count {
        font-size: 15px;
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .rowContent {
    padding: 0 30px;
  }
  .draftQueue {
    padding: 20px 40px;
  }
  .summaryHeader {
    .colTitle {
      width: 100%;
    }
  }
  .summaryContent {
    flex-wrap: wrap;
    justify-content: flex-start;
    .progressWrapper {
      width: 33.3%;
      margin-bottom: 10px;
      &:nth-child(3n) {
        border-width: 0;
      }
      &:nth-child(4n) {
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .modal {
    max-width: 100% !important; // override antd style
    margin: 0;
  }
  .headerWrapper {
    padding: 0 15px;
  }
  .rowContent {
    padding: 0 15px;
  }
  .draftQueueWrapper {
    margin: 30px 0;
  }
  .draftQueue {
    padding: 20px 20px;
  }
  .summaryHeader {
    flex-wrap: wrap;
    .title {
      font-size: 20px;
    }
    .summaryLinkWrapper {
      text-align: left;
      margin-top: 10px;
      margin-bottom: -5px;
      button {
        padding-left: 0;
      }
    }
    :global {
      .ant-col {
        width: 100%;
      }
    }
  }
  .summaryContent {
    flex-wrap: wrap;
    justify-content: flex-start;
    .progressWrapper {
      width: 49%;
      margin-bottom: 10px;
      &:nth-child(2n) {
        border-width: 0;
      }
      &:nth-child(3n),
      &:nth-child(5n) {
        padding-left: 0;
        border-width: 1px;
      }
      &:nth-child(4n) {
        padding-left: 15px;
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .pageMessageWrapper {
    display: block;
  }
  .headerWrapper {
    padding: 0;
  }
  .aircraftRegistration {
    display: none;
  }
  .aircraftDescription {
    margin-left: 5px;
    font-size: 14px;
  }
  .modalHeader {
    img {
      width: 20px;
    }
  }
  .draftQueueWrapper {
    margin: 15px 0;
  }
  .summaryHeader {
    flex-wrap: wrap;
    .title {
      font-size: 18px;
    }
  }
  .contentWrapper {
    padding: 0 15px;
    padding-bottom: 30px;
  }
  .summaryContent {
    flex-wrap: wrap;
    justify-content: flex-start;
    .progressWrapper {
      width: 49%;
      margin-bottom: 10px;
      border-width: 0 !important;
      padding-left: 0 !important;
    }
  }
  .buttonWrapper {
    flex-direction: column-reverse;
    .allButtons {
      display: flex;
    }
  }
  .listTitle {
    display: none;
  }
  .listTitleMobile {
    display: block;
    .titleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .allButtons {
      justify-content: flex-start;
      padding: 30px 0 20px;
      .acceptButton {
        margin-left: 0;
      }
    }
    .filtersDrawerWrapper {
      button {
        padding: 0;
      }
    }
  }
}
