@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.toggleButtons {
  display: flex;
  justify-content: flex-end;
  padding-right: 53px;
  .buttonToggle {
    padding: 0 5px;
    margin: 0 5px;
    border-radius: 10px;
    border: solid 0.5px #b9b9b9;
    display: flex;
    align-items: center;
    background-color: transparent;
    .badge1,
    .badge2,
    .badge3,
    .badge4,
    .badge5 {
      margin-right: 5px;
      height: 11px;
      width: 11px;
      border-radius: 50%;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
      color: #646464;
    }
  }
  .buttonToggleOff {
    padding: 0 5px;
    margin: 0 5px;
    border-radius: 10px;
    border: solid 0.5px #b9b9b9;
    background-color: #e7e7e7;
    :global {
      .ant-badge-status-dot {
        width: 11px;
        height: 11px;
      }
    }
    span {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
      color: #bababa;
    }
  }
}

.hideToggleButtons {
  display: none;
}

.badge1 {
  background-image: linear-gradient(to bottom, #024574, #012140);
}

.badge2 {
  background-image: linear-gradient(to bottom, #72c9ff, #3f98ff);
}

.badge3 {
  background-image: linear-gradient(to bottom, #a0d2ff, #7cafdd);
}

.badge4 {
  background-image: linear-gradient(to bottom, #3552cd, #001e9b);
}

.badge5 {
  background-image: linear-gradient(to bottom, #b1f2ec, #79e0d4);
}
