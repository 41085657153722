@import '~antd/lib/style/themes/default.less';
@import '~../../utils/utils.less';

.lastBtn {
  margin-left: 8px;
}

.firstBtn {
  margin-right: 8px;
}

.downloadButton {
  display: flex;
  margin-left: 8px;
}

.allButtons {
  display: flex;
  justify-content: flex-end;
}

:global {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
    margin-left: -5px;
  }
}

.monthPickerWrapper {
  display: flex;
  height: 32px;
  margin-bottom: 8px;
  justify-content: flex-end;
}

.logbookTable tbody tr:first-child td {
  text-align: right;
  background-color: @offWhite;
  font-weight: 500;
  color: @black;
}
.logbookTable tbody tr:first-child td:nth-child(n + 2) {
  text-align: left;
}
.logbookTable tbody tr:first-child td:nth-child(n + 4) {
  font-weight: 500;
  color: @black;
  background-color: @tableAccent2;
}

.logbookTable tbody tr:last-child td {
  font-weight: 500;
  color: @black;
  background-color: @tableAccent1;
}

.logbookTable tbody tr:first-child td:last-child {
  background-color: @offWhite;
}

.logbookTable tbody tr:last-child td:last-child {
  background-color: @offWhite;
}

.logbookTable tbody tr:last-child td:nth-last-child(2) {
  background-color: @offWhite;
}

.logbookTable tbody tr:last-child td[datatype='engoil'] {
  background-color: @offWhite;
}

.logbookTable tbody tr:last-child td:nth-child(2) {
  background-color: @offWhite;
}

.logbookTable tbody tr:last-child td:nth-child(3) {
  background-color: @offWhite;
}

.logbookTable tbody tr:last-child td:first-child {
  text-align: right;
  background-color: @offWhite;
  font-weight: 500;
  color: @black;
}

.componentsTableDemoNested .ant-table-expanded-row > td:last-child {
  padding: 0 48px 0 8px;
}

.componentsTableDemoNested .ant-table-expanded-row > td:last-child .ant-table-thead th {
  border-bottom: 1px solid @lightGray;
}

.componentsTableDemoNested .ant-table-expanded-row > td:last-child .ant-table-thead th:first-child {
  padding-left: 0;
}

.componentsTableDemoNested .ant-table-expanded-row > td:last-child .ant-table-row td:first-child {
  padding-left: 0;
}

.componentsTableDemoNested .ant-table-expanded-row .ant-table-row:last-child td {
  border: none;
}

.componentsTableDemoNested .ant-table-expanded-row .ant-table-thead > tr > th {
  background: none;
}

.componentsTableDemoNested .tableOperation a:not(:last-child) {
  margin-right: 24px;
}

.componentsTableDemoNested .ant-table-expanded-row:hover > td {
  background: @offWhite;
}

.tableList {
  max-height: calc(100vh - 290px);
  overflow-y: scroll;
  .tableListOperator {
    margin-bottom: 16px;
    button {
      margin-right: 8px;
    }
  }
}

.emptyText {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  img {
    height: 115px;
  }
  span {
    color: @darkGrey;
    font-size: 14px;
    padding-top: 8px;
  }
}

.tableListForm {
  :global {
    .ant-form-item {
      margin-bottom: 24px;
      margin-right: 0;
      display: flex;
      > .ant-form-item-label {
        width: auto;
        line-height: 32px;
        padding-right: 8px;
      }
      .ant-form-item-control {
        line-height: 32px;
      }
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .submitButtons {
    white-space: nowrap;
    margin-bottom: 24px;
  }
}

.columnLandings {
  word-break: keep-all;
}

@media screen and (max-width: @screen-lg) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 24px;
  }

  .monthPickerWrapper {
    display: flex;
    height: 32px;
    margin-bottom: 8px;
    justify-content: flex-end;
  }
}

@media screen and (max-width: @screen-md) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
  .downloadButton {
    margin-left: 8px;
    margin-bottom: 8px;
  }

  :global {
    .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
      margin-left: -5px;
    }
  }

  .allButtons {
    display: flex;
    float: left;
  }

  .monthPickerWrapper {
    display: flex;
    height: 32px;
    margin-bottom: 8px;
    justify-content: flex-start;
  }
}

@media screen and (max-width: @screen-sm) {
  .downloadButton {
    margin-bottom: 0;
    margin-left: 0;
  }
  .allButtons {
    display: inline-block;
    float: none;
  }
}
