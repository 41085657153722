@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.radioGroup {
  :global {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: rgba(23, 144, 255, 0.15);
      color: #1790ff;
    }
    .ant-radio-button-wrapper {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.4px;
      color: #c9c9c9;
    }
  }
}
