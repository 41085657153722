@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.syncButtonWrapper {
  margin-bottom: 15px;
  width: 200px;
  button,
  .syncButton,
  .syncButtonSyncing {
    cursor: pointer;
    margin-right: 5px;
    border-radius: 50px;
    width: 24px;
    height: 24px;
    background-color: @brandAccentBlue;
  }
  .timeUnit {
    font-weight: 500;
  }
  .syncButtonSyncing {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
