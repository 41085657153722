@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.iconGroup {
  i {
    transition: color 0.32s;
    color: @blackMedium;
    cursor: pointer;
    margin-left: 16px;
    &:hover {
      color: @blackMedium;
    }
  }
}

.backIcon {
  margin-top: 4px;
}

.headerList {
  margin-bottom: 4px;
}

.tabsCard {
  :global {
    .ant-card-head {
      padding: 0 16px;
    }
  }
}

.noData {
  color: @lightGray;
  text-align: center;
  line-height: 64px;
  font-size: 16px;
  i {
    font-size: 24px;
    margin-right: 16px;
    position: relative;
    top: 3px;
  }
}

.heading {
  color: @black;
  font-size: 20px;
}

.stepDescription {
  font-size: 14px;
  position: center;
  text-align: center;
  & > div {
    margin-top: 8px;
    margin-bottom: 4px;
  }
}

.textSecondary {
  color: @blackMedium;
}

.defectSignature {
  width: 500px;
}

.resolutionSignature {
  width: 600px;
}

.attachmentCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  :global {
    .ant-card-cover {
      text-align: center;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .stepDescription {
    left: 8px;
  }
}

.canvasWrapper {
  font-weight: 500;
}
.canvas-wrapper {
  position: relative;
}
.canvasWrapper::after {
  content: '';
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  pointer-events: none;
}

.formItemInputDeferral {
  margin-right: 5px;
}

@media screen and (max-width: @screen-xl) {
  .headerList {
    position: relative;
    :global {
      td {
        vertical-align: baseline;
      }
    }
  }
  .cardDescriptionList {
    :global {
      td {
        vertical-align: baseline;
      }
      span {
        display: block;
      }
    }
  }
}

.toolTipIcon {
  margin-right: 10px;
}

.card {
  margin-bottom: 24px;
}

.cardDescriptionList {
  margin-bottom: 16px;
}

.signatuerImg {
  width: 500px;
}

.defectImageCard {
  width: 300px;
}

.authSignatureImg {
  width: 600px;
}

.spin {
  width: 100%;
  margin: 40px 0;
}

.spinIcon {
  font-size: 40px;
}

.divider {
  margin: 16px 0;
}
