@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.tripCard,
.tripCardMobile {
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px @white;
  background-color: rgba(255, 255, 255, 0);
  margin-top: 20px;
  .tripHeaderWrapper,
  .tripHeaderWrapperNoSectors {
    display: flex;
    justify-content: space-between;
    .numberWrapper {
      display: flex;
      align-items: center;
      margin-top: 11px;
      margin-bottom: 10px;
      .tripNumber {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.39px;
        color: #000;
        margin-left: 15px;
        margin-right: 10px;
      }
      .tripDate {
        font-size: 12px;
        letter-spacing: 0.39px;
        color: #bfbfbf;
      }
    }
    .nameWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .peopleNames {
        font-size: 12px;
        letter-spacing: 0.39px;
        color: #000;
        padding-right: 15px;
        display: flex;
        flex-wrap: wrap;
        .firstOfficerDiv {
          margin-left: 4px;
        }
      }
    }
    .pdfWrapper {
      min-width: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      letter-spacing: 0.39px;
      padding-right: 15px;
      .pdfIcon {
        margin: 0 2px;
      }
    }
    .SRPPdf {
      padding-left: 15px;
      border-left: solid 1px #e6e8ed;
    }
  }
  .tripListWrapper {
    margin-left: 26px;
    margin-right: 26px;
  }
  .tripHeaderWrapper {
    border-bottom: solid 1px #f4f5f7;
  }
}

.tripCardMobile {
  display: none;
}

.rowWrapper {
  width: 100%;
  min-height: 32px;
  align-items: center;
  padding: 10px 0;
  border-bottom: solid 1px #f4f5f7;
  letter-spacing: 0.39px;
  display: flex;
  flex-wrap: wrap;
  &:last-child {
    border-bottom: none;
  }
  .statusWrapper {
    display: flex;
    float: left;
    height: 23px;
    align-items: center;
    margin-right: 8px;
    .statusMessageText {
      padding: 3px 5px 2px 5px;
    }
    .statusBadgeGreen,
    .statusBadgeOrange,
    .statusBadgeBlue,
    .statusBadgeLightGrey,
    .statusBadgeRed {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 11px 9px 11px 0;
      background-image: linear-gradient(to bottom, #7bc88d, #4aa91c);
    }
    .statusBadgeOrange {
      background-image: linear-gradient(to bottom, #fad288, #f2a650);
    }
    .statusBadgeBlue {
      background-image: linear-gradient(233deg, #81bfff 37%, #57aaff 10%);
    }
    .statusBadgeLightGrey {
      background-image: linear-gradient(to top, #d2d2d2, #e0e0e0);
    }
    .statusBadgeRed {
      background-image: linear-gradient(to bottom, #ff7474, #ff4040);
    }
    .statusMessageOrange,
    .statusMessageBlue,
    .statusMessageLightGrey,
    .statusMessageRed {
      border-radius: 3px;
      border-style: solid;
      border-width: 1px;
      border-image-slice: 1;
      background-origin: border-box;
      background-clip: content-box, border-box;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.39px;
    }
    .statusMessageOrange {
      border-image-source: linear-gradient(to bottom, #fad288, #f2a650);
      background-image: linear-gradient(to bottom, #fcfcfc, #fcfcfc), linear-gradient(to bottom, #fad288, #f2a650);
      color: #ffce72;
    }
    .statusMessageBlue {
      border-image-source: linear-gradient(233deg, #81bfff 37%, #57aaff 10%);
      background-image: linear-gradient(to bottom, #f4f9fd, #f4f9fd), linear-gradient(233deg, #81bfff 37%, #57aaff 10%);
      color: #1990ff;
    }
    .statusMessageLightGrey {
      border-image-source: linear-gradient(to top, #d2d2d2, #e0e0e0 -100%);
      background-image: linear-gradient(to bottom, #fcfcfc, #fcfcfc), linear-gradient(to top, #d2d2d2, #e0e0e0 -100%);
      color: #bfbfbf;
    }
    .statusMessageRed {
      border-image-source: linear-gradient(to bottom, #ff7474, #ff4040);
      background-image: linear-gradient(to bottom, #fcfcfc, #fcfcfc), linear-gradient(to bottom, #ff7474, #ff4040);
      color: #e7293a;
    }
    .statusMessageHidden {
      display: none;
    }
  }
  .depArrWrapper {
    display: flex;
    height: 23px;
    align-items: center;
  }
  .sectorNumber {
    font-size: 12px;
    font-weight: 500;
    margin-right: 15px;
    color: #646464;
  }
  .airportLabel {
    min-width: 35px;
    font-size: 12px;
    color: #000;
  }
  .timeLabel {
    width: 45px;
    margin-left: 7px;
    font-size: 12px;
    color: #bfbfbf;
  }
  .depArrDivider {
    margin: 0 20px;
    color: #bfbfbf;
  }
  .openFlightChevron {
    margin-left: 11px;
    color: #1990ff;
  }
}

@media screen and (max-width: @screen-sm) {
  .tripCard {
    display: none;
  }
  .tripCardMobile {
    display: block;
    .tripListWrapper {
      margin: 0 4px;
    }
    .rowWrapper {
      .statusWrapper {
        margin-right: 0;
        .statusBadgeGreen,
        .statusBadgeOrange,
        .statusBadgeBlue,
        .statusBadgeLightGrey,
        .statusBadgeRed {
          margin-right: 4px;
        }
      }
      .sectorNumber {
        margin-right: 5px;
      }
      .statusMessageOrange,
      .statusMessageBlue,
      .statusMessageLightGrey,
      .statusMessageRed {
        display: none;
      }
      .timeLabel {
        margin-left: 4px;
      }
      .depArrDivider {
        margin: 0 8px;
      }
      .openFlightChevron {
        margin-left: 5px;
      }
    }
    .tripHeaderWrapper,
    .tripHeaderWrapperNoSectors {
      display: block;
      .mobileTripHeader {
        display: flex;
        .tripNumber {
          margin-left: 4px;
          margin-right: 5px;
        }
        .tripDate {
          margin-right: 5px;
        }
      }
      .mobileSRPLayout {
        display: flex;
        margin-bottom: 10px;
        .SRPPdf {
          border: none;
          padding-left: 4px;
        }
      }
    }
  }
}
