@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.layoutDiv {
  width: 100%;
  display: flex;
  .mainCol {
    width: 100%;
    height: auto;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 1000px;
  }
  .filtersCol {
    width: 250px;
    float: right;
    height: 100%;
  }
}

.bottomBoundary {
  width: 100%;
  height: 1px;
}

.stickyFilters {
  height: 100%;
}

.filtersCol {
  padding-left: 40px !important; // .ant-col is getting inline styles from antd, hence !important is required

  :global {
    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: 0;
    }
  }
}

.spacer {
  height: 26px;
}

.mxListCol,
.mxItemsImporter {
  margin-bottom: 26px;
}

.filtersDrawerWrapper {
  display: none;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .syncButtonWrapper {
    margin-bottom: 15px;
    min-width: 250px;
    button,
    .syncButton {
      cursor: pointer;
      margin-right: 5px;
      border-radius: 50px;
      width: 24px;
      height: 24px;
      background-color: @brandAccentBlue;
    }
    .syncButton {
      width: auto;
      color: @white;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .filtersCol {
    padding-left: 30px !important;
  }
}

@media screen and (max-width: @screen-lg) {
  .buttonText {
    display: none !important; // overriding antd styles
  }
  .filtersCol {
    display: none;
  }
  .filtersDrawerWrapper {
    width: 30%;
    display: inline-block;
    text-align: right;
    button {
      position: relative;
      top: 3px;
    }
  }
}
@media screen and (max-width: @screen-sm) {
  .buttonText {
    display: none !important; // overriding antd styles
  }
  .overCardTitle {
    margin-left: 16px;
  }
}
