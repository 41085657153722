@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.tileWrapper {
  width: 100%;
  height: 56px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px @white;
  background-color: rgba(255, 255, 255, 0);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2px;
}

.tileWrapper:last-child {
  margin-bottom: 0;
}

.icon {
  margin: 0 16px;
}

.title {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.39px;
  color: #818181;
}

.boxBody {
  margin: 0;
  display: grid;
  place-items: center;
  margin-right: 6px;
}

.squareCounter {
  width: 45px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: @white;
}

.squareCounterWrapper {
  width: 47px;
  height: 44px;
  padding: 1px;
  border-radius: 5px;
}

.limitationText {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #5a5a5a;
}

.limitationList {
  padding-inline-start: 16px;
  margin-bottom: 0;
}

.collapseWrapper {
  margin-bottom: 15px;
  a:visited,
  a:hover,
  a:focus {
    text-decoration: none;
    color: @white;
  }
  :global {
    .ant-collapse {
      border: none;
      background-color: transparent;
    }
    .ant-collapse > .ant-collapse-item {
      border: none;
    }
    .ant-collapse-content {
      box-shadow: 0 8px 10px 0 rgba(219, 227, 237, 0.41);
      border: solid 2px @white;
      background-color: rgba(255, 255, 255, 0);
    }
    .ant-collapse-content > .ant-collapse-content-box {
      border: none;
    }
    .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
      padding: 0;
      padding-top: 2px;
      height: 56px;
      border-radius: 5px;
    }
    .src-components-AircraftStatusCollapseContent-AircraftStatusCollapseContent-module__contentWrapper--nZ4Pe {
      margin-bottom: 20px;
    }
    .src-components-AircraftStatusCollapseContent-AircraftStatusCollapseContent-module__contentWrapper--nZ4Pe:last-child {
      margin-bottom: 0;
    }
  }
}

.collapseWrapper:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 375px) {
  .collapseWrapper {
    :global {
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 16px 2px;
      }
    }
  }
}
