@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.pageHeader {
  background: @white;
  padding: 30px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 18px;
    letter-spacing: 0.07;
    font-weight: 500;
    color: @titleDarkBlack;
    margin-bottom: 4px;
  }

  .content {
    opacity: 0.7;
    color: @titleDarkBlack;
  }

  .action {
    position: relative;
  }

  .title,
  .action,
  .content,
  .extraContent,
  .main {
    flex: auto;
  }
}

@media screen and (max-width: @screen-md) {
  .pageHeader {
    .row {
      display: flex;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .pageHeader {
    padding: 24px 16px;
    .detail {
      display: block;
    }
    .colAction {
      margin-left: 15px;
    }
  }
}
