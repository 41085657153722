@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.aircraftSettingsHeader {
  padding: 16px 16px 0 16px;
  border-bottom: 1px solid @white;
  & + div {
    margin: 0;
  }
  :global {
    .ant-btn {
      font-weight: 500;
    }
    .ant-row {
      padding: 4px 0;
    }
  }
}

.aircraftSettingsContent {
  background: @white;
  padding: 16px 16px;
  margin: 0;
  .row {
    display: flex;
    width: 100%;
    .col {
      display: flex;
      flex-wrap: wrap;
    }
  }
  :global {
    .ant-form-explain {
      display: none;
    }
    .ant-card-body {
      padding: 20px 15px;
    }
  }
  .tableHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
  }
}

.settingsHeader {
  display: flex;
  justify-content: space-between;
  .settingsTitle {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.03px;
    color: @formEntries;
    padding-top: 8px;
  }
  .settingsEditButton {
    border: none;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.03px;
    text-align: right;
    color: @blue;
    box-shadow: none;
  }
}

.thresholdHeader {
  display: flex;
  justify-content: space-between;
  .thresholdTitle {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.03px;
    color: @formEntries;
    padding-top: 8px;
  }
  .thresholdEditButton {
    border: none;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.03px;
    text-align: right;
    color: @blue;
    box-shadow: none;
  }
}

.aircraftImage {
  width: 75%;
  height: 75px;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 15px;
}

.partTitle {
  font-size: 16px;
  font-weight: 500;
  color: @titleBlack;
  margin-bottom: 8px;
}

.crewTitle {
  font-size: 16px;
  font-weight: 500;
  color: @titleBlack;
  margin-top: 10px;
}

.addButtonDiv {
  margin-bottom: 2px;
  height: 32px;
}

.crewSearch {
  width: 250px;
  :global {
    .ant-select-selection {
      border: none;
      box-shadow: none;
    }
  }
}

.detailsForm {
  display: flex;
  justify-content: center;
  .formRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .cellInput {
      color: @darkGrey;
    }
  }
  :global {
    .ant-row {
      width: 100%;
    }
    .ant-col {
      margin: 0 -6px;
    }
    .ant-form-item {
      margin: 10px 0 0 0;
      .ant-form-item-control-wrapper {
        .ant-form-item-children {
          div {
            height: 34px;
          }
        }
      }
      .ant-btn {
        border-radius: 2px;
        height: 40px;
        font-weight: 700;
        margin-left: 0;
        margin-bottom: 12px;
      }
      .ant-form-item-label {
        line-height: 16px;
      }
      .ant-form-item-control {
        line-height: 30px;
        font-weight: 600;
        padding: 0;
      }
      label {
        max-width: 96px;
        height: 14px;
        font-size: 12px;
        text-transform: uppercase;
        color: @fontGray;
      }
    }
  }
  .buttonDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    padding-right: 15px;
    .cancelButton {
      display: block;
      padding-right: 10px;
      :global {
        .ant-btn {
          border-color: @transparent;
        }
      }
    }
    .cancelButtonHidden {
      display: none;
    }
  }
  .fuelCapacityEditDiv {
    display: flex;
    .showUnit {
      padding-left: 4px;
    }
    .hideUnit {
      display: none;
    }
  }
  .fuelCapacityDiv {
    display: flex;
  }
  .errorDivHidden {
    display: none;
  }
}

.editButtonDiv {
  display: flex;
  justify-content: center;
  margin: 10px;
  button {
    margin: 0 10px;
  }
}

.filterBox {
  width: 220px;
}

.addNewButton {
  width: auto;
  margin-bottom: 8px;
  float: right;
  z-index: 5;
  border-radius: 25px;
}

@media screen and (max-width: @screen-xs) {
  .detailsColumn {
    :global {
      .ant-form-item-label {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .aircraftSettingsHeader {
    :global {
      .antd-pro-components-page-header-index-title {
        margin-bottom: 0;
      }
    }
  }
  .detailsColumn {
    .aircraftImage {
      width: 68%;
      height: 66px;
      background-position: center left;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 24px 0 15px 15px;
    }
  }

  .aircraftSettingsContent {
    padding: 8px 16px;
  }

  .crewTitle {
    margin-top: 20px;
  }

  .addButtonDiv {
    height: 32px;
    margin-top: 10px;
    .addNewButton {
      width: 32px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      .addButtonText {
        display: none;
      }
    }
  }
}

@media screen and (max-width: @screen-xl) {
  :global {
    .antd-pro-pages-aircraft-settings-tableHeader {
      margin-top: 20px;
    }
  }
}

@media screen and (width: 1200px) {
  :global {
    .antd-pro-pages-aircraft-settings-tableHeader {
      margin-top: 0;
    }
  }
}
