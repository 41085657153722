@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.aircraftMenuWrapper {
  margin: 0;
  margin-top: -5px;
  padding: 0;
  list-style-type: none;
  button {
    border: none;
    background-color: transparent;
  }
  .menuIcon {
    margin-right: 15px;
    width: 16px;
  }
  li {
    padding: 18px 20px;
    &.activeLink {
      background-color: @brandAccentBlue;
      a {
        color: @white;
        &:hover {
          color: @innerMenuWhite;
        }
      }
    }
  }
  a {
    display: flex;
    color: @innerMenuWhite;
    font-weight: 300;
    font-size: 14px;
    transition: color 0.3s;
    &:hover {
      color: @white;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .aircraftMenuWrapper {
    &.aircraftMobileHeader {
      display: flex;
      .moreIcon {
        margin: 25px;
      }
      li {
        display: none;
        &.activeLink {
          padding: 12px 20px;
          margin-top: 5px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .aircraftMenuWrapper {
    &.aircraftMobileHeader {
      li {
        padding: 15px;
      }
    }
  }
}
