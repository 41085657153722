@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.progressWrapper {
  display: flex;
  padding-bottom: 15px;
  span {
    width: 140px;
  }
}

.showMoreButton {
  float: right;
  border-radius: 10px;
  border: solid 0.5px #b9b9b9;
  margin: 10px 5px 0 5px;
  padding: 0 5px;
  background-color: transparent;
}
