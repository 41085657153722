.dropdown {
  :global(.ant-dropdown-menu-item-group-list) {
    padding: 0;
  }
}
:global {
  .ant-back-top-icon {
    background-size: contain !important;
  }
}
.headerWrapper {
  margin-bottom: -13px;
  border-bottom: none;
}
