@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.aircraftWrapper {
  margin-right: 27px;
  .currentStatus {
    font-size: 16px;
    font-weight: 600;
    color: @overviewStatusTitle;
    margin-bottom: 30px;
  }
}

.aircraftImg {
  width: 75%;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.aircraftModel {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.12px;
  text-align: center;
  color: #42475b;
  margin: 20px 0;
}

.estimateLabel {
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  background-color: #f5f5f5;
}

.infoMiddleRow {
  margin: 25px 0;
}

.aircraftStatusLocation {
  width: 100%;
  padding-right: 15px;
}

.aircraftStatus {
  margin-top: 15px;
}

.infoLabel {
  font-size: 12px;
  color: #b9b9b9;
  text-transform: uppercase;
}

.aircraftLocation {
  margin-top: 30px;
}

.aircraftLocationInfo {
  margin-bottom: 5px;
  span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.46px;
    color: #646464;
  }
}

.aircraftLocationInfoSingle {
  margin-bottom: 5px;
}

.lastFlown {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

@media screen and (max-width: @screen-lg) {
  .aircraftImg {
    height: 75px;
  }
}
