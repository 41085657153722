@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.itemTitleTag {
  border: 0;
  background: @defectBlue;
  color: @white;
  border-radius: 12px;
  line-height: 1.5;
  padding: 0 8px;
  margin: 0 4px;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
}

.itemTitleTagsch {
  background: @scheduledBlue;
}

.itemTitleTagllp {
  background: @llpBlue;
}

.itemTitleTagoop {
  background: @oopBlue;
}

.itemTitleTagPackage {
  background: @pkgBlue;
}

.iconPkg {
  height: 10px;
  margin-right: 1px;
}
