@import '~antd/lib/style/themes/default.less';
@import '../../../utils/utils.less';

.modal {
  min-width: 660px;
  :global {
    .ant-modal-body {
      background-color: @offWhite;
    }
    .ant-form-item-label {
      font-weight: 600;
    }
    .ant-upload.ant-upload-drag {
      height: 270px;
      background-color: @white;
    }
    .ant-calendar-picker {
      width: 100%;
    }
  }
}

.modalBodyWrapper {
  .operatorName {
    span {
      color: @blackMedium85;
      font-weight: 600;
    }

    margin-bottom: 20px;
  }

  display: flex;
  .modalDraggerContainerWrapper {
    :global {
      .ant-form-item {
        width: 282px;
        margin-bottom: 0;
      }
    }
    .flexibleDraggerWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .draggerContent {
        .fileTypes {
          color: @blackMedium85;
          font-weight: 600;
        }
        .clickText {
          font-weight: 400;
        }

        display: flex;
        height: 50%;
        flex-direction: column;
        width: 100%;
        vertical-align: middle;
        margin: auto;
        .schematicsImg {
          width: 125px;
          margin: auto;
        }
        .uploadImg {
          margin: auto auto 10px auto;
        }
        .textContainer {
          display: flex;
          flex-direction: column;
          margin: 0 auto auto auto;
          .textContent {
            margin: auto;
            text-align: center;
            .uploadText {
              font-weight: 300;
              color: @lighterGray;
            }
            .clickText {
              color: @blue;
            }
          }
        }
      }
    }
  }
  .filePreviewWrapper {
    display: flex;
    flex-direction: column;
    height: 270px;
    width: 282px;
    background-color: @grayExtraLight;
    border: 1px solid @lightGrayMedium;
    .filePreviewContainer {
      padding: 14px 14px 0 14px;
      overflow: hidden;
      position: relative;
      height: 100%;
      .filePreviewContainerInner {
        display: flex;
        height: auto;
        .viewerContainer {
          margin: auto;
          display: flex;
          vertical-align: middle;
          text-align: center;
          .imageContainer {
            box-shadow: -5px -5px 8px 2px @grayExtraLight, 5px -5px 8px 2px @grayExtraLight;
            transition: all 0.5s ease;
            margin: auto;
            .pdfImage {
              max-height: 192px;
              overflow: hidden;
              -webkit-mask-image: linear-gradient(@black, 65%, @transparent 100%);
            }
            .fileImage {
              max-height: auto;
              max-width: 100%;
              overflow: hidden;
            }
          }
        }
      }
      .deleteDocumentContainer {
        .buttonItem {
          display: flex;
          flex-direction: row;
          color: @white;
          border-color: @gray;
          position: absolute;
          border-radius: 4px;
          height: 40px;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          cursor: pointer;
          .buttonTextContainer {
            cursor: pointer;
            margin: auto 0;
            vertical-align: middle;
            display: flex;
            .deleteIcon {
              opacity: 1;
              padding-right: 5px;
            }
            .deleteText {
              margin: auto 0;
            }
          }
        }
      }
    }

    .filePreviewContainer:hover {
      .filePreviewContainerInner {
        opacity: 0.7;
        background-color: @black;
        .viewerContainer {
          .imageContainer {
            box-shadow: none;
            .pdfImage {
              opacity: 0.3;
            }
            .fileImage {
              opacity: 0.3;
            }
          }
        }
      }
    }

    .fileNameWrapper {
      padding: 20px 14px 20px 14px;
      .fileNameContainer {
        .buttonTextContainer {
          margin: auto 0;
        }

        .fileNameEdit {
          width: 100%;
          :global {
            .ant-form-item {
              width: 100%;
            }
          }
        }
        .fileNameNonEdit {
          display: flex;
          justify-content: center;
          vertical-align: middle;
          text-align: center;
          width: 100%;
          cursor: pointer;
          .editIcon {
            margin-right: 5px;
          }
          .editText {
            font-weight: 500;
            color: @titleBlack;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .modalInputContainerWrapper {
    width: auto;
    height: 100%;
    .inputContainerWrapper {
      .optionsDropdown {
        font-weight: 400;
      }
      .optionalText {
        color: @blackMedium;
        font-weight: normal;
      }
    }
    .nonInputContainerWrapper {
      span {
        font-weight: 400;
        color: @blackMedium;
      }

      margin-top: 6px;
      margin-bottom: 28px;
    }
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  .loadingSpinner {
    margin: auto;
    text-align: center;
  }
}

@media screen and (min-width: @screen-md) {
  .modalInputContainerWrapper {
    padding-left: 25px;
  }
  :global {
    .ant-modal-header {
      padding-right: 40px;
      padding-left: 40px;
    }
    .ant-modal-body {
      padding-right: 40px;
      padding-left: 40px;
    }
    .ant-modal-footer {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
}

@media screen and (max-width: @screen-md) {
  :global {
    .ant-modal {
      max-width: calc(95vw);
      min-width: 0;
    }
    .ant-modal-header {
      padding-right: 14px;
      padding-left: 14px;
    }
    .ant-modal-body {
      padding: 24px 14px 24px 14px;
    }
    .ant-modal-footer {
      padding-right: 14px;
      padding-left: 14px;
    }
    .ant-calendar-picker {
      width: 100%;
    }
    .ant-form-vertical .ant-form-item {
      margin-bottom: 0;
    }
  }
  .modalBodyWrapper {
    display: flex;
    flex-direction: column;

    .modalDraggerContainerWrapper {
      :global {
        .ant-form-item {
          width: 100%;
        }
      }
    }

    .filePreviewWrapper {
      display: flex;
      flex-direction: column;
      height: 270px;
      width: 100%;
      .filePreviewContainer {
        .filePreviewContainerInner {
          .viewerContainer {
            .imageContainer {
              opacity: 1;
              .pdfImage {
                box-shadow: -5px -5px 8px 2px @grayExtraLight, 5px -5px 8px 2px @grayExtraLight;
                max-height: 192px;
                -webkit-mask-image: linear-gradient(@black, 65%, @transparent 100%);
              }
              .fileImage {
                opacity: 1;
                max-height: auto;
                max-width: 100%;
                overflow: hidden;
              }
            }
          }
        }
        .deleteDocumentContainer {
          display: none;
        }
      }

      .filePreviewContainer:hover {
        .filePreviewContainerInner {
          opacity: 1;
          .viewerContainer {
            .imageContainer {
              .pdfImage {
                opacity: 1;
              }
              .fileImage {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
