@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.activeAircraftWrapper {
  padding: 15px 20px;
  display: flex;
  height: auto;
  align-items: flex-start;
  margin: 10px 0;
  &:hover {
    .toggleWrapper {
      transform: translateY(2px);
    }
  }
}
.iconWrapper {
  padding-top: 5px;
}
.acWrapper {
  width: 85px;
  margin-left: 15px;
  text-align: left;
}
.regWrapper {
  font-size: 16px;
  font-weight: 600;
  color: @white;
  letter-spacing: 0.6px;
  text-align: left;
  :global {
    .ant-badge-status-text {
      margin-left: 4px;
    }
  }
}
.statusWrapper {
  font-size: 12px;
  color: @white;
  opacity: 0.7;
  font-weight: 300;
  margin-top: -3px;
}

.toggleWrapper {
  color: @white;
  font-size: 12px;
  padding-top: 1px;
  transition: all 0.4s;
}

.aircraftMenu {
  background: @contextMenuBlue;
  width: 200px;
  box-shadow: -7px 5px 12px 5px #091729;
  padding-bottom: 25px;
  li {
    padding: 3px 8px;
    &:hover {
      background: @contextMenuBlue;
    }
    &:focus {
      outline: 0;
    }
  }
}

.inputSearchReg {
  margin-bottom: 10px;
  color: @white;
  letter-spacing: 0.8px;
  i {
    color: @white;
  }
  input {
    background: @brandAccentBlue;
    border-color: @brandAccentBlueDark;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    color: @white;
    .placeholder({color: @white;});
  }
  input::selection {
    background: #1470c7;
  }
}

a.aircraftLinkSingleWrapper {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin: 0;
  background: @brandBoldBlue;
  border-radius: 4px;
  .aircraftRegistration {
    display: inline-block;
    width: 85px;
    color: @white80;
    font-weight: 500;
    transition: all 0.3s;
  }
  .aircraftType {
    display: inline-block;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    color: @innerMenuWhite;
    font-size: 13px;
    transition: all 0.3s;
  }
  .aircraftStatus {
    display: inline-block;
    width: 10px;
  }
  .aircraftStatusText {
    display: none;
  }
  &:hover {
    .aircraftRegistration {
      color: @white;
    }
    .aircraftType {
      color: @white80;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .activeAircraftWrapper {
    margin: 0;
    padding: 13px 20px;
  }
  .iconWrapper {
    img {
      width: 28px;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .activeAircraftWrapper {
    padding: 13px 12px;
    &:hover {
      .toggleWrapper {
        transform: none;
      }
    }
  }
  .aircraftMenu {
    width: 100%;
    border: 0;
    padding-bottom: 0;
    :global {
      li.ant-menu-item {
        padding: 3px 0;
        height: auto;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        &.ant-menu-item-selected {
          background: transparent;
        }
      }
    }
    a.aircraftLinkSingleWrapper {
      padding: 2px 10px;
      .aircraftRegistration {
        width: 35%;
      }
      .aircraftType {
        width: 30%;
      }
      .aircraftStatus {
        width: 35%;
        display: flex;
      }
      .aircraftStatusText {
        display: block;
        color: @innerMenuWhite;
        opacity: 0.8;
      }
    }
  }
}
