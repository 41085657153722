@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.searchHolder {
  display: flex;
  align-items: center;
  height: 32px;
  :global(.ant-btn) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    border-color: transparent;
    background-color: transparent;
  }
}

.searchIconHolder {
  position: relative;
  z-index: 9;
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  &:focus {
    background: transparent;
  }
  &:hover {
    background: transparent;
  }
}

.searchIcon {
  height: 16px;
  position: relative;
  top: 1px;
  cursor: pointer;
  transition: all 0.4s;
  animation: searchIconActiveSlideBack 0.4s;
  &.searchIconActive {
    left: 10px;
    animation: searchIconActiveSlide 0.4s;
  }
}

.searchInputHolder {
  position: relative;
}

.searchInput {
  min-width: 270px;
  border-radius: 20px;
  padding: 5px 30px;
  margin-left: -15px;
  height: auto;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden;
  &.searchInputActive {
    opacity: 1;
    visibility: visible;
    border-radius: 16px;
    padding-left: 30px;
    animation: searchInputVisibility 1s;
    outline: none;
    &:focus {
      border: solid 1px @brandAccentBlue;
    }
  }
}

.closeIcon {
  :global(.anticon) {
    color: black;
  }
}

.closeIconHolder {
  position: absolute;
  z-index: 9;
  right: 8px;
  top: 9px;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden;
  color: @black;
  cursor: pointer;
  border: none;
  background-color: transparent;
  &.closeIconHolderActive {
    opacity: 0.25;
    visibility: visible;
    &:hover {
      color: @black;
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .searchInput {
    min-width: 210px;
  }
  .searchHolder {
    width: 210px;
  }
}

@media screen and (max-width: @screen-sm) {
  .searchHolder {
    width: 100%;
    position: relative;
  }
  .searchInputHolder {
    width: 100%;
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 0;
    opacity: 0.25;
    margin-left: 10px;
    margin-right: -10px;
  }
  .searchInput {
    opacity: 1;
    visibility: visible;
    width: 100%;
    margin-left: 0;
  }
  .closeIconHolder {
    opacity: 0;
    visibility: hidden;
    &.closeIconHolderActive {
      opacity: 0;
      visibility: hidden;
      &:hover {
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: @screen-xs) {
  .searchInput {
    min-width: 200px;
  }
}

@keyframes searchIconActiveSlide {
  from {
    left: 226px;
  }
  to {
    left: 10px;
  }
}

@keyframes searchIconActiveSlideBack {
  from {
    left: -212px;
  }
  to {
    left: 0;
  }
}

@keyframes searchInputVisibility {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
